<template>
  <div class="news_box">
    <!--头部-->
    <header_public></header_public>
    <!-- 顶部背景图 模块 -->
    <div class="news_special">
      <div></div>
    </div>
    <div class="newsPageTitle">
      <span class="newsborderTitle"
        >积云IT培训>新闻动态>{{ topArray[0].titleText }}</span
      >
    </div>
    <!--文章内容-->
    <div class="article_box con_style">
      <div class="article_left">
        <div class="article_title">
          <h1>{{ archives.title }}</h1>
          <div class="article_title_text">
            <span>发布时间：{{ archives.format_publishtime }}</span>
            <span>{{ archives.views }}次浏览</span>
            <span>来源：{{ topArray[0].titleText }}</span>
            <div class="issharebox">
              <span>分享到</span>
              <vshare class="grayscale"></vshare>
            </div>
          </div>
        </div>
        <div class="article_con text_justify" v-html="archives.content"></div>
        <div class="article_con_Bottom">
          <div v-if="preartInfo" @click="toPageInfo(preartInfo.id)">
            上一篇：{{ preartInfo.title }}
          </div>
          <div v-else>上一篇：没有了</div>
          <div v-if="nextartInfo" @click="toPageInfo(nextartInfo.id)">
            下一篇：{{ nextartInfo.title }}
          </div>
          <div v-else>下一篇：没有了</div>
        </div>
      </div>
      <div class="article_right">
        <div class="hot-article">
          <div class="article-title">
            <p>{{ sideArray[0].titleText }}</p>
          </div>
          <div class="article-list">
            <ul>
              <li v-for="(item, idx) in newslist2" :key="idx">
                <img
                  src="../../assets/images/article-icon.png"
                  alt="文章"
                  title="文章"
                />
                <p @click="tonewsInfo(item.id, sideArray[0].typeNum)">
                  {{ item.title ? item.title : "" }}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="hot-article">
          <div class="article-title">
            <p>{{ sideArray[1].titleText }}</p>
          </div>
          <div class="article-list">
            <ul>
              <li v-for="(item, idx) in newslist3" :key="idx">
                <img
                  src="../../assets/images/article-icon.png"
                  alt="文章"
                  title="文章"
                />
                <p @click="tonewsInfo(item.id, sideArray[1].typeNum)">
                  {{ item.title ? item.title : "" }}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="hot-article">
          <div class="article-title">
            <p>{{ sideArray[2].titleText }}</p>
          </div>
          <div class="article-list">
            <ul>
              <li v-for="(item, idx) in newslist4" :key="idx">
                <img
                  src="../../assets/images/article-icon.png"
                  alt="文章"
                  title="文章"
                />
                <p @click="tonewsInfo(item.id, sideArray[2].typeNum)">
                  {{ item.title ? item.title : "" }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--底部组件-->
    <bottom_public></bottom_public>
  </div>
</template>

<script>
import bottom_public from "../../components/bottom_public_new/bottom_public_new";
import header_public from "../../components/header_public/header_public";

const axios = require("axios").default;

export default {
  data() {
    return {
      titleArray: [
        {
          titleText: "积云动态",
          typeNum: 0,
          id: "18",
          isTitle: false,
        },
        {
          titleText: "行业动态",
          typeNum: 1,
          id: "20",
          isTitle: false,
        },
        {
          titleText: "教学动态",
          typeNum: 2,
          id: "21",
          isTitle: false,
        },
        {
          titleText: "就业动态",
          typeNum: 3,
          id: "22",
          isTitle: false,
        },
      ],
      archives: {},
      loading: false,
      noData: false,
      typeNum: null,
      topArray: [],
      sideArray: [],
      newsList: [],
      newsList2: "",
      newsList3: "",
      newsList4: "",
      nextartInfo: "",
      preartInfo: "",
    };
  },
  methods: {
    // 获取文章详情
    getArchivesDetail(id) {
      axios
        .get("/api/block/getArchivesDetail?id=" + id)
        .then(({ data: { data = {} } }) => {
          this.archives = data;
        });
    },
    // 获取热门文章
    getNewsList() {
      if (this.loading || this.noData) {
        return;
      }
      this.loading = true;
      axios
        .get("/api/block/getArchivesList?limit=7&status=hot")
        .then(({ data: { data = [] } }) => {
          this.loading = false;
          if (data.length > 0) {
            this.newsList = data;
            console.log(this.newsList);
          } else {
            this.noData = true;
          }
        });
    },
    getnextpage(id) {
      axios
        .get("/api/block/getarchivespage?id=" + id)
        .then(({ data: { data = [] } }) => {
          this.preartInfo = data.preart[0];
          this.nextartInfo = data.nextart[0];
        });
    },
    setTitle(type) {
      var topArray = [];
      var sideArray = [];
      var pageSize = this.pageSize;
      var page = this.page;
      this.titleArray[type].isTitle = true;

      topArray = this.titleArray.filter((item) => {
        return item.isTitle == true;
      });

      sideArray = this.titleArray.filter((item) => {
        return item.isTitle == false;
      });

      this.sideArray = sideArray;
      this.topArray = topArray;

      this.getsideListRequest(sideArray);
    },
    getsideListRequest(arr) {
      axios
        .get("/api/block/getArchivesList?limit=6&catid=" + arr[0].id)
        .then((res) => {
          this.newslist2 = res.data.data;
        });
      axios
        .get("/api/block/getArchivesList?limit=6&catid=" + arr[1].id)
        .then((res) => {
          this.newslist3 = res.data.data;
        });
      axios
        .get("/api/block/getArchivesList?limit=6&catid=" + arr[2].id)
        .then((res) => {
          this.newslist4 = res.data.data;
        });
    },
    toPageInfo(id) {
      let type = this.topArray[0].typeNum;

      var { href } = this.$router.resolve({
        path: "/news_detail/" + id,
        query: {
          type: type,
        },
      });
      window.open(href, "_blank");
    },
    tonewsInfo(id, type) {
      var { href } = this.$router.resolve({
        path: "/news_detail/" + id,
        query: {
          type: type,
        },
      });
      window.open(href, "_blank");
    },
    // 点击事件
    change_article(res) {
      let id = res;
      this.getArchivesDetail(id);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
  },
  components: {
    bottom_public,
    header_public,
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  },
  created() {
    const {
      params: { id },
    } = this.$route;
    this.getArchivesDetail(id);
    this.getNewsList();

    var titleType = this.$route.query.type || 0;
    this.getnextpage(id);

    this.setTitle(titleType);
  },
};
</script>

<style lang="scss" scoped>
.issharebox {
  display: flex;
  align-items: center;
}

.grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -webkit-filter: grayscale(1);
  display: inline-block;
  margin-left: 10px;
}

.news_special {
  width: 100rem;
  height: 30vh;
  background: url("../../assets/images/newListtop2.png") no-repeat
    rgb(27, 27, 165);
  background-size: cover;

  margin: 0 auto;
  margin-top: 30px;
}

.news_special div {
  width: 17.552vw;
  height: 4.79vw;
  position: absolute;
  bottom: 9vw;
  left: 14.5vw;
}

.newsPageTitle {
  width: 100rem;
  height: 60px;
  line-height: 60px;
  font-size: 1.125rem;
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
}

.newsborderTitle {
  width: 100%;
  height: auto;
  border-left: 4px #f8a900 solid;
  text-align: left;
  font-family: MicrosoftYaHei;
  font-size: 1.125rem;
  color: #242424;
  padding-left: 1.25rem;
}

.text_justify {
  text-align: justify;
}

.con_style {
  width: 100rem;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.article_left {
  width: 75rem;
  padding-right: 2rem;
}

.article_right {
  width: max-content;
}

.hot-subject,
.hot-article {
  width: 100%;
  height: auto;
  padding-top: 1.1vw;
  background: #ffffff;
  box-shadow: 0px 1px 10px 1px rgba(185, 180, 180, 0.24);
}

.hot-article {
  margin-top: 2.2vw;
  margin-left:1vw;
}

.subject-title,
.article-title {
  width: 100%;
  height: 2.5vw;
  padding-left: 1.1vw;
  border-left: 5px solid #0000ff;
}

.article-title {
  border-left: 5px solid #fc3530 !important;
}

.subject-title p,
.article-title p {
  font-size: 1.75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #242424;
  line-height: 2.5vw;
  text-align: left;
}

.subject-list ul {
  width: 18.6vw;
  margin: 2vw auto 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.subject-list ul li {
  width: 8.6vw;
  height: 5.9vw;
  margin-bottom: 1.45vw;
  cursor: pointer;
  position: relative;
}

.subject-list ul li:hover div {
  display: block;
}

.subject-list ul li > div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.subject-list ul li > div a {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 5.9vw;
}

.subject-list ul li img {
  width: 100%;
  height: 100%;
}

.article-list ul {
  width: 18.6vw;
  margin: 0.5vw auto 0;
  padding-left: 1vw;
}

.article-list ul li {
  width: 100%;
  height: 3.3vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid #f5f8fa;
}

.article-list ul li:last-child {
  border-bottom: none;
}

.article-list ul li img {
  width: 0.8vw;
  height: 1.1vw;
  margin-right: 0.8vw;
}

.article-list ul li p {
  display: inline-block;
  width: 100%;
  line-height: 3.3vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.article-list ul li p a,
.article-list ul li p a:active,
.article-list ul li p a:hover {
  outline: none;
  text-decoration: none;
  font-size: 1.08rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #242424;
}

.news_header {
  width: 100%;
  height: 7.4vw;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  background-color: rgb(14, 28, 76);
}

.navigation {
  width: 62.5vw;
  height: 100%;
  // margin: 0 auto;
  display: -webkit-flex;
  /* Safari */
  display: flex;
  justify-content: space-between;
}

.lefLogo {
  width: 13.2vw;
  height: 100%;
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.lefLogo img {
  width: 100%;
  /*height: 55px;*/
  /*height: 60%;*/
}

.rightNav {
  width: 46.875vw;
  height: 100%;
}

.navList {
  height: 100%;
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-family: PingFang;
  font-weight: 500;
}

.navList li {
  color: #ffffff;
  font-size: 0.9375rem;
}

.navList li a {
  font-size: 0.9375rem;
}

.link {
  margin: 0 20px;
}

a {
  color: #ffffff;
  outline: none;
  text-decoration: none;

  &:hover,
  &:active {
    -webkit-tap-highlight-color: transparent;
  }
}

.news_brand {
  width: 100%;
  height: 27.031vw;
  background-color: red;
}

.news_brand img {
  width: 100%;
  height: 100%;
}

.article_box {
  margin-top: 1rem;
  margin-bottom: 14.75rem;
}

.article_title {
  margin: 0 0 3.28rem;
  text-align: left;
  border-bottom: 1px solid #999999;

  padding: 0 1rem;
  padding-bottom: 6px;
}

.article_title_text {
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  align-items: center;
  color: #999999;
  font-family: "微软雅黑";
  font-weight: 200;
}

.article_title h1 {
  font-size: 1.5rem;
  font-family: "微软雅黑", Georgia, Serif;
  font-weight: bolder;
  color: rgba(0, 0, 0, 1);
  letter-spacing: 1px;
  margin-bottom: 1.25rem;
}

.article_title img {
  margin-top: 0.625rem;
}

.article_title p {
  font-size: 1.125rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: rgba(136, 136, 136, 1);
  line-height: 3.4375rem;
}

.article_con img {
  width: 100%;
  height: 33.645vw;
}

.article_con_Bottom {
  width: 100%;
  height: auto;
  margin-top: 3.25rem;
  border-top: 1px solid #999999;
  font-family: "微软雅黑", Georgia, Serif;
}

.article_con_Bottom div {
  width: 100%;
  height: 3.25rem;
  line-height: 3.25rem;
  text-align: left;

  cursor: pointer;
}
</style>
