<template>
	<div>
		<div class="foot_box width-100" :style="{ background: bgcolor }">
			<div class="con_box">
				<div style="display: flex;justify-content: center;">
					<div>
						<div class="flex-start">
							<div>
								<div class="flex-center">
									<p class="header-title">北京校区地址：</p>
									<p class="explain">北京市海淀区上庄路白水洼77号</p>
								</div>
								<div class="flex-center">
									<p class="header-title">河南校区地址：</p>
									<p class="explain">郑州市航空港区航田智能手机产业园</p>
								</div>
							</div>
						</div>
						<div class="flex-center" style="justify-content:center;margin-top:3vw">
							<img src="../../assets/videoImg/lianxi.png" alt="">
							<p class="header-title">联系我们：</p>
							<p class="explain lianxi">400-800-2320</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div :style="{ background: aboutBg}" class="about-box">
			<div class="about-us">
				<p class="about-us-title">关于我们</p>
				<p>积云教育是一家提供高端数字人才培训和高薪就业整体解决方案的教育集团，始终秉持“人人皆可成才”的育人理念、以“创新教育、成就未来”为使命、以“高就业率、高就业薪资、高对口就业率”为培养目标、坚持“产教融合、校企合一”。集团总部位于北京，现拥有北京、河南等多个线下大校区，累计服务全国35000多名学员
				</p>
			</div>
		</div>
		<div class="copyright">
			<p>
				Copyright 2018 - {{year}} Usian.cn 北京优思安科技有限公司
			</p>
			<p>
				版权所有
				<a href="https://beian.miit.gov.cn" target="_blank">京ICP备14057463号-1</a>
			</p>
		</div>
	</div>
</template>
<script>
	const axios = require("axios").default;
	export default {
		props: {
			aboutContent: {
				type: String,
			},
			bgcolor: {
				type: String,
			},
			aboutBg: {
				type: String,
			},
		},
		data() {
			return {
				links: [], //友情链接
				year: new Date().getFullYear(),
			};
		},
		created() {
			this.getLinks();
		},
		methods: {
			toTop() {
				// chrome
				document.body.scrollTop = 0;
				// firefox
				document.documentElement.scrollTop = 0;
				// safari
				// window.pageYOffset = 0;
			},
			getLinks() {
				axios
					.get("/api/block/getItemsByName?name=" + encodeURIComponent("友情链接"))
					.then((res) => {
						this.links = res.data.data;
						// console.log("友情链接", this.links);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.width-100 {
		width: 100%;
	}
	.about-box{
		padding: 6vw 0 6.7vw 0;
	}
	.flex-center {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.flex-start {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start
	}

	.flex {
		display: flex;
		display: -webkit-flex;
	}

	.about-us {
		font-family: SourceHanSansSC, SourceHanSansSC;
		font-weight: 500;
		font-style: normal;
		text-transform: none;
		padding: 0 7.5vw;
		font-size: 2.9vw;
		color: #303030;
		line-height: 4.4vw;
		text-align: left;
	}

	.about-us-title {
		font-family: SourceHanSansSC, SourceHanSansSC;
		font-weight: 500;
		font-size: 3.5vw;
		color: #303030;
		line-height: 4.5vw;
		text-align: center;
		font-style: normal;
		text-transform: none;
		margin-bottom: 2.7vw;
	}

	.explain {
		font-family: SourceHanSansSC-Medium;
		font-weight: 500;
		font-size: 3.2vw;
		color: #F4F4F4;
		text-align: right;
		font-style: normal;
		text-transform: none;
	}

	.lianxi {
		font-size: 3.5vw;
	}

	.header-title {
		font-family: SourceHanSansSC, SourceHanSansSC;
		font-weight: 500;
		font-size: 3.5vw;
		color: #FFFFFF;
		text-align: right;
		font-style: normal;
		text-transform: none;
		margin-left: 11px;
	}

	.more-title {
		margin-left: 0;
		text-align: left;
		margin-bottom: 10px;
	}

	.foot_box {
		padding: 4.5vw 0;
		position: relative;
	}

	.con_box {
		margin: 0 auto;
	}

	.or_code div {
		width: 74px;
		height: 74px;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: space-around;
	}

	.or_code div img {
		width: 74px;
		height: 74px;
	}

	.or_code p {
		margin-top: 19px;
	}

	.copyright {
		width: 100%;
		height: 7.3vw;
		font-size: 2.9vw;
		font-weight: 500;
		background: #303030;
		padding: 4.8vw 0;
	}

	.copyright p,
	.copyright a {
		color: #FFFFFF;
	}

	.copyright a {
		text-decoration: none;
	}


	.links {
		width: 1200px;
		margin: 0 auto;
		text-align: left;
	}

	.links a {
		font-size: 16px;
		font-weight: 500;
		line-height: 1.875vw;
		color: #919298;
		margin-right: 16px;
		text-decoration: none;
	}

	.or_code>div {
		margin-right: 20px;
	}
</style>