<template>
	<div style="cursor:pointer">
		<!-- 头部组件 -->
		<header_public></header_public>
		<Video_masks ref="video"></Video_masks>
		<!-- @click="$refs.video.checkVideoFun(courselist[0] ? courselist[0]['image'] : '')" -->
		<div class="news_special" style="cursor:pointer">
			<div></div>
		</div>
		<div class="newsPageTitle">
			<span class="newsborderTitle">积云IT培训>新闻动态>{{topArray[0].titleText}}</span>
		</div>

		<div class="pagebody">
			<div class="news_con">
				<ul class="direction_row flex_wrap">
					<li class='flex_style itemBorder' v-for="(item,idx) in newsList" :key="idx">
						<a class='flex_style' :href="'https://www.usian.cn/news_detail/'+item.id+'?type='+typeNum" target="_blank">
							<div class="newsTop_con">
								<img :src="item.image ? item.image : ''" alt="图片" />
							</div>
							<div class="newsBottom_con">
								<div class="justify_between newsItem">
									<p class='newsTitle'>{{item.title ? item.title : ''}}</p>
									<p class='newsContent'>{{item.description ? item.description : ''}}</p>
								</div>
								<div class="newsDate">
									<p>{{formatDate(item.createtime)}}</p>
									<p>阅读：{{item.views}}</p>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>
			<div class="sideList">
				<div class="hot-article">
					<div class="article-title">
						<p>{{sideArray[0].titleText}}</p>
					</div>
					<div class="article-list">
						<ul>
							<li v-for="(item, idx) in newslist2" :key="idx">
								<img src="../../assets/images/article-icon.png" alt="文章" title="文章" />
								<p>
									<a :href="'https://www.usian.cn/news_detail/'+item.id+'?type='+sideArray[0].typeNum" target="_blank">
									{{item.title ? item.title : ""}}
									</a>
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="hot-article">
					<div class="article-title">
						<p>{{sideArray[1].titleText}}</p>
					</div>
					<div class="article-list">
						<ul>
							<li v-for="(item, idx) in newslist3" :key="idx">
								<img src="../../assets/images/article-icon.png" alt="文章" title="文章" />
								<p>
									<a :href="'https://www.usian.cn/news_detail/'+item.id+'?type='+sideArray[1].typeNum" target="_blank">
									{{item.title ? item.title : ""}}
									</a>
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="hot-article">
					<div class="article-title">
						<p>{{sideArray[2].titleText}}</p>
					</div>
					<div class="article-list">
						<ul>
							<li v-for="(item, idx) in newslist4" :key="idx">
								<img src="../../assets/images/article-icon.png" alt="文章" title="文章" />
								<p>
									<a :href="'https://www.usian.cn/news_detail/'+item.id+'?type='+sideArray[2].typeNum" target="_blank">
									{{item.title ? item.title : ""}}
									</a>
								</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<mo-paging :page-index="currentPage" :total="count" :page-size="pageSize" @change="pageChange">
		</mo-paging>
		<bottom_public></bottom_public>
	</div>
</template>

<script>
	import MoPaging from '../../components/pagination/pagination.vue'
    import header_public from "../../components/header_public/header_public";
	import bottom_public from "../../components/bottom_public_new/bottom_public_new";
	import {
		getScrollHeight,
		getScrollTop,
		getWindowHeight,
	} from "../../util/screen";
	import Video_masks from "../../components/video_masks/video_masks";
	const axios = require("axios").default;
	export default {
		metaInfo: {
			title: "新闻资讯-[积云教育]",
			meta: [{
					name: "description",
					content: "本栏目发布了积云教育相关的文章供学员查看。",
				},
				{
					name: "keywords",
					content: "新闻资讯",
				},
			],
		},
		data() {
			return {
				pageSize: 6, //每页显示20条数据
				currentPage: 1, //当前页码
				count: 12, //总记录数
				newsList: [],
				page: 1,
				loading: false,
				noData: false,
				videoSrc: "",
				videoState: false,
				imgSrc: "",
				imgState: false,
				courselist: {},
				titleArray: [{
					titleText: '积云动态',
					typeNum: 0,
					id: '18',
					isTitle: false,
				}, {
					titleText: '行业动态',
					typeNum: 1,
					id: '20',
					isTitle: false,
				}, {
					titleText: '教学动态',
					typeNum: 2,
					id: '21',
					isTitle: false,
				}, {
					titleText: '就业动态',
					typeNum: 3,
					id: '22',
					isTitle: false,
				}],
				typeNum: null,
				topArray: [],
				sideArray: [],
				newslist2: [],
				newslist3: [],
				newslist4: []
			};
		},
		methods: {
			load() {
				var pageSize = this.pageSize
				var page = this.page
				this.getNewsList();
				this.getcourselist();
			},
			tonewsInfo(id, type) {
				var {
					href
				} = this.$router.resolve({
					path: '/news_detail/' + id,
					query: {
						type: type
					}
				});
				window.open(href, '_blank');
			},
			todetailPage(id) {
				let typeNum = this.typeNum
				let routeData = this.$router.resolve({
					path: "/news_detail/" + id,
					query: {
						type: typeNum,
					}
				});
				window.open(routeData.href, '_blank');
			},
			setTitle(type) {
				var topArray = []
				var sideArray = []
				var pageSize = this.pageSize
				var page = this.page
				this.titleArray[type].isTitle = true

				topArray = this.titleArray.filter(item => {
					return item.isTitle == true
				})

				sideArray = this.titleArray.filter(item => {
					return item.isTitle == false
				})

				this.sideArray = sideArray
				this.topArray = topArray
				this.getsideListRequest(sideArray)
				this.getNewsListRequest(pageSize, page, topArray[0].id)
			},
			pageChange(page) {
				var pageSize = this.pageSize
				this.currentPage = page
				this.getNewsListRequest(pageSize, page, this.topArray[0].id)
			},
			formatDate(value) {
				let date = new Date(value * 1000);
				let y = date.getFullYear();
				let MM = date.getMonth() + 1;
				MM = MM < 10 ? ('0' + MM) : MM;
				let d = date.getDate();
				d = d < 10 ? ('0' + d) : d;
				let h = date.getHours();
				h = h < 10 ? ('0' + h) : h;
				let m = date.getMinutes();
				m = m < 10 ? ('0' + m) : m;
				let s = date.getSeconds();
				s = s < 10 ? ('0' + s) : s;
				return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
			},
			getNewsList() {
				var pageSize = this.pageSize
				var page = this.page
			},

			//新闻列表
			getNewsListRequest(pageSize, page, catid) {
				this.loading = true;
				axios.get("/api/block/getArchivesList?catid=" + catid + "&limit=" + pageSize + "&page=" + page)
					.then(res => {
						var res = res.data
						this.count= res.code, //总记录数
						this.loading = false;
						this.newsList = res.data;
					});
			},
			//新闻列表
			getsideListRequest(arr) {
				axios.get("/api/block/getArchivesList?limit=6&catid=" + arr[0].id).then((res) => {
					this.newslist2 = res.data.data;
				});
				axios.get("/api/block/getArchivesList?limit=6&catid=" + arr[1].id).then((res) => {
					this.newslist3 = res.data.data;
				});
				axios.get("/api/block/getArchivesList?limit=6&catid=" + arr[2].id).then((res) => {
					this.newslist4 = res.data.data;
				});
			},
			getcourselist() {
				axios
					.get("/api/block/getItemsByName?name=" + encodeURIComponent("课程5.0"))
					.then((res) => {
						this.courselist = res.data.data; //积云影音列表数据
					});
			},
		},
		components: {
			header_public,
			MoPaging,
			bottom_public,
			Video_masks,
		},
		created() {
			var titleType = this.$route.query.type || 0

			this.typeNum = titleType

			this.load();
			this.setTitle(titleType);
		},
		mounted() {
			this.$router.afterEach((to, from, next) => {
				window.scrollTo(0, 0)
			})
		},
	};
</script>

<style lang="scss" scoped>
	.hot-subject,
	.hot-article {
		width: 100%;
		height: auto;
		padding-top: 1.1vw;
		background: #ffffff;
		box-shadow: 0px 1px 10px 1px rgba(185, 180, 180, 0.24);
	}

	.hot-article {
		margin-top: 2.2vw;
	}

	.subject-title,
	.article-title {
		width: 100%;
		height: 2.5vw;
		padding-left: 1.1vw;
		border-left: 5px solid #0000ff;
	}

	.article-title {
		border-left: 5px solid #fc3530 !important;
	}

	.subject-title p,
	.article-title p {
		font-size: 1.75rem;
		font-family: PingFang SC;
		font-weight: 500;
		color: #242424;
		line-height: 2.5vw;
		text-align: left;
	}

	.subject-list ul {
		width: 18.6vw;
		margin: 2vw auto 0;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
	}

	.subject-list ul li {
		width: 8.6vw;
		height: 5.9vw;
		margin-bottom: 1.45vw;
		cursor: pointer;
		position: relative;
	}

	.subject-list ul li:hover div {
		display: block;
	}

	.subject-list ul li>div {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.5);
		display: none;
	}

	.subject-list ul li>div a {
		display: inline-block;
		width: 100%;
		height: 100%;
		font-size: 1.6rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #ffffff;
		line-height: 5.9vw;
	}

	.subject-list ul li img {
		width: 100%;
		height: 100%;
	}

	.article-list ul {
		width: 18.6vw;
		margin: 0.5vw auto 0;
	}

	.article-list ul li {
		width: 100%;
		height: 3.3vw;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-bottom: 2px solid #f5f8fa;
	}

	.article-list ul li:last-child {
		border-bottom: none;
	}

	.article-list ul li img {
		width: 0.8vw;
		height: 1.1vw;
		margin-right: 0.8vw;
	}

	.article-list ul li p {
		display: inline-block;
		width: 100%;

		line-height: 3.3vw;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: left;
	}

	.article-list ul li p a,
	.article-list ul li p a:active,
	.article-list ul li p a:hover {

		font-size: 1.08rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #242424;
	}

	a {
		outline: none;
		text-decoration: none;
	}

	// 侧边栏
	.sideList {
		width: 21.6vw;
		height: auto;
		margin-left: 50px;
	}

	.sideListItem {
		width: 340px;
		height: auto;
		padding: 15px;
		border: 2px solid #e6e6e6;
		box-sizing: border-box;
		margin-bottom: 30px;
	}

	.sideListTitle {
		width: 100%;
		height: auto;
		border-left: 4px #f8a900 solid;
		text-align: left;
		text-indent: 1em;
		font-family: MicrosoftYaHei;
		font-size: 25px;
		color: #242424;
	}

	.sideListContent {
		width: 100%;
		height: auto;
		font-size: 1.6rem;
		color: #000000;
		text-align: left;
		margin-top: 1.25rem;
	}

	.sideListContent .sideImg {
		margin-right: 10px;
	}

	.sideListContent li {
		width: 100%;
		height: 2.5rem;
		line-height: 2.5rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		border-top: 2px solid #f5f8fa;
		font-size: 1.125rem;
	}

	.sideListContent li:first-child {
		border: none
	}


	.flex_style {
		display: flex;
		width: 100%;
		height: auto;
	}

	.itemBorder {
		padding-bomttom: 6%;
		border-bottom: 1px solid #e6e6e6;
	}

	.newsItem {
		width: 100%;
		color: #c6c6c6;
	}

	.newsTitle {
		color: #0b0809;
		margin-bottom: 20px;
		font-size: 1.4rem;
	}

	.newsContent {
		width: 100%;
		height: auto;
		font-size: 1.2rem;
		color: #999999;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		margin-bottom: 30px;
	}

	.newsDate {
		width: 60%;
		display: flex;
		justify-content: space-between;
		color: #999999;
	}

	.direction_row {
		-webkit-flex-direction: row;
		-moz-flex-direction: row;
		-ms-flex-direction: row;
		-o-flex-direction: row;
		flex-direction: row;
	}

	.direction_column {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}

	.flex_wrap {
		-webkit-flex-wrap: wrap;
		-moz-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		-o-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.flex_nowrap {
		-webkit-flex-wrap: nowrap;
		-moz-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		-o-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.align_flex-start {
		-webkit-align-items: flex-start;
		-moz-align-items: flex-start;
		-ms-align-items: flex-start;
		-o-align-items: flex-start;
		align-items: flex-start;
	}

	.align_center {
		-webkit-align-items: center;
		-moz-align-items: center;
		-ms-align-items: center;
		-o-align-items: center;
		align-items: center;
	}

	.align_end {
		-webkit-align-items: flex-end;
		-moz-align-items: flex-end;
		-ms-align-items: flex-end;
		-o-align-items: flex-end;
		align-items: flex-end;
	}

	.justify_start {
		-webkit-justify-content: flex-start;
		-moz-justify-content: flex-start;
		-ms-justify-content: flex-start;
		-o-justify-content: flex-start;
		justify-content: flex-start;
	}

	.justify_end {
		-webkit-justify-content: flex-end;
		-moz-justify-content: flex-end;
		-ms-justify-content: flex-end;
		-o-justify-content: flex-end;
		justify-content: flex-end;
	}

	.justify_center {
		-webkit-justify-content: center;
		-moz-justify-content: center;
		-ms-justify-content: center;
		-o-justify-content: center;
		justify-content: center;
	}

	.justify_around {
		-webkit-justify-content: space-around;
		-moz-justify-content: space-around;
		-ms-justify-content: space-around;
		-o-justify-content: space-around;
		justify-content: space-around;
	}

	.justify_between {
		-webkit-justify-content: space-between;
		-moz-justify-content: space-between;
		-ms-justify-content: space-between;
		-o-justify-content: space-between;
		justify-content: space-between;
	}

	#header {
		background: rgb(20, 33, 114) !important;
		opacity: 0.9;
	}

	.news_special {
		width: 100rem;
		height: 30vh;
		background: url("../../assets/images/newslistTop.png") no-repeat rgb(27, 27, 165);
		background-size: cover;
		margin: 0 auto;
		margin-top: 30px;
	}

	.news_special div {
		width: 17.552vw;
		height: 4.79vw;
		position: absolute;
		bottom: 9vw;
		left: 14.5vw;
	}

	.newsPageTitle {
		width: 100rem;
		height: 60px;
		line-height: 60px;
		font-size: 1.125rem;
		margin: 0 auto;
		text-align: left;
		box-sizing: border-box;
	}

	.newsborderTitle {
		width: 100%;
		height: auto;
		border-left: 4px #f8a900 solid;
		text-align: left;
		font-family: MicrosoftYaHei;
		font-size: 1.125rem;
		color: #242424;
		padding-left: 30px;
	}

	.pagebody {
		width: 100rem;
		height: auto;
		margin: 0 auto;
		display: flex;
	}


	.news_con {
		width: 65vw;
		font-size: 1.2rem;
		font-family: PingFang;
	}

	.news_con ul li {
		width: 100%;
		margin-bottom: 42px;
		box-sizing: border-box;
		padding-bottom: 18px;
	}

	.newsTop_con {
		width: 16vw;
		height: 10vw;
	}

	.newsTop_con img {
		width: 16vw;
		height: 10vw;
	}

	.newsBottom_con {
		width: 100%;
		text-align: left;
		margin-left: 3.33vw;
	}

	.newsBottom_con div:nth-child(1) img {
		width: 0.78125vw;
		height: 0.572vw;
		vertical-align: middle;
		margin-right: 0.5vw;
	}

	.newsBottom_con div:nth-child(1) span {
		font-size: 1rem;
		font-family: PingFang;
		font-weight: 500;
		color: rgba(101, 101, 101, 1);
		vertical-align: middle;
	}

	.newsBottom_con div:nth-child(2) {
		margin: 1.145vw 0 1.5625vw;
	}

	.newsBottom_con div:nth-child(2) div {
		width: 2.864vw;
		height: 2.864vw;
		border-radius: 3px;
		background: linear-gradient(109deg,
				rgba(253, 43, 186, 1),
				rgba(131, 5, 241, 1));
	}

	.newsBottom_con div:nth-child(2) div span {
		font-size: 1.125rem;
		font-family: PingFang;
		font-weight: 500;
		color: rgba(255, 255, 255, 1);
	}

	.newsBottom_con div:nth-child(2) div span:nth-child(2) {
		font-size: 0.75rem !important;
	}

	.newsBottom_con p a,
	.newsBottom_con p a:active,
	.newsBottom_con p a:hover {
		outline: none;
		text-decoration: none;
		color: rgba(27, 25, 91, 1);
	}

	.newsBottom_con div:nth-child(3) p {
		font-size: 1rem;
		font-family: PingFang SC;
		font-weight: 300;
		color: rgba(136, 136, 136, 1);
	}

	.font16 {
		font-size: 1.6rem;
	}

	@media screen and (max-width: 1256px) {
		.newsBottom_con div:nth-child(2) div {
			width: 3vw;
			height: 3vw;
		}
	}

	@media screen and (max-width: 1200px) {
		.newsBottom_con div:nth-child(2) div {
			width: 3.3vw;
			height: 3.3vw;
		}
	}

	@media screen and (max-width: 1090px) {
		.newsBottom_con div:nth-child(2) div {
			width: 3.6vw;
			height: 3.6vw;
		}
	}
</style>
