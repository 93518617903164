import { render, staticRenderFns } from "./short_video.vue?vue&type=template&id=2fbdcb78&scoped=true&"
import script from "./short_video.vue?vue&type=script&lang=js&"
export * from "./short_video.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/dsp/dsp_pc.css?vue&type=style&index=0&id=2fbdcb78&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fbdcb78",
  null
  
)

export default component.exports