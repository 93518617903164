<template lang="">
    <div>
        <div v-if="ismobile">
            <indexYD />
        </div>
        <div v-else>
            <indexPC />
        </div>
    </div>
</template>
<script>
    import indexYD from '../index/components/indexYD.vue';
    import indexPC from '../index/components/indexPC.vue';
    export default {
        computed: {
            ismobile() {
                var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
                return isMobile
            },
        },
        components: {
            indexYD,
            indexPC
        },
        data() {
            return {
            }
        },
        mounted() {
            this.ismobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            window.addEventListener("resize", this.handleResize);
        },
        methods: {
            handleResize() {
                var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
                return isMobile
            }
        },
    }
</script>
<style lang="">

</style>