<template>
    <div class="page" :style="{ height:isCollapse ? '100vh' : 'auto'}" style="background-color: #F8F3FA;">
        <header_public :navindex='1' :color="headerColor" v-if="!ismobile"></header_public>
        <header_public_yd v-if="ismobile" :navindex='1' @addClose="addClose" class="fixed-header"></header_public_yd>
        <div class="mybanner swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img class="swiper-banner-img" style="width: 100%" :data-src="bannerBgPC"
                        v-if="!ismobile"   />
                    <img class="swiper-banner-img" :data-src="bannerBgYD" v-else   />
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div>
            <!-- 全民短视频时代  把握流量新风口 -->
            <div class="big-title">全民短视频时代 把握流量新风口</div">
                <img class="title-line" :data-src="titleLineBg"  >
                <div class="big-box">
                    <div>
                        <div v-if="ismobile" class="video-title-box">
                            短视频赛道火热 创收无处不在
                        </div>
                        <div class="flex">
                            <div class="bianxian-box" v-for="(item,index) in bianxianList" :key="index">
                                <div>
                                    <img :data-src="item.imgurl" alt="" class="dy-img">
                                    <p class="title-bianxian">{{ ismobile ? item.name : '变现渠道'}}</p>
                                    <p class="shuoming" v-if="!ismobile">{{item.tip}}<br />{{item.tip2}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="grid-item">
                            <div class="grid-info">
                                <div v-for="(item, index) in themeList" :key="item.theme" class="top-box">
                                    <div class="tip-box" :style="{
                                        backgroundImage:  ismobile ? 'url(' + item.imgUrlYD + ')' : 'url(' + item.imgUrl + ')',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                      }">
                                        <p class="two-title">{{ item.theme }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="grid-info">
                                <div v-for="(item, index) in themeList2" :key="item.theme" class="bottom-box">
                                    <div class="tip-box" :style="{
                                        backgroundImage:  ismobile ? 'url(' + item.imgUrlYD + ')' : 'url(' + item.imgUrl + ')',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                      }">
                                        <p class="two-title">{{ item.theme }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 5大课程优势  提升你的就业竞争力 -->
            <div class="ys-con">
                <div class="big-title big-titles">5大课程优势 提升你的就业竞争力</div>
                <img class="title-line title-line-ys" :data-src="titleLineBg2" v-if="!ismobile">
                <img class="title-line title-line-ys" :data-src="titleLineBg" v-else>
                <div class="ys-list flex flex_b" v-if="!ismobile">
                    <div v-for="(item,index) in classList" :key="item.title">
                        <div class="ys-item" :style="{
                            'background-image':
                                'url(' +
                                (item.imgUrl) +
                                ')',
                        }">
                            <div class="top-num flex flex_c"><span>{{item.num}}</span> <span class="add-class">+</span>
                            </div>
                            <div class="top-title">{{item.title}}</div>
                            <div class="ys-item-con flex flex_c">
                                <div>
                                    <div class="yuan"></div>
                                    <div>{{item.tip1}}</div>
                                </div>
                                <div>
                                    <div class="yuan"></div>
                                    <div>{{item.tip2}}</div>
                                </div>
                                <div>
                                    <div class="yuan"></div>
                                    <div>{{item.tip3}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else style="margin-bottom: 8.1vw;">
                    <div class="ys-yd-list">
                        <div v-for="(item,index) in classList" :key="item.title">
                            <div class="top-yd" :style="{ background: item.color }">
                                {{ item.num }} + {{ item.title }}
                            </div>
                            <div class="ys-item-con flex flex_c">
                                <div>
                                    <div class="yuan"></div>
                                    <div>{{item.tip1}}</div>
                                </div>
                                <div>
                                    <div class="yuan"></div>
                                    <div>{{item.tip2}}</div>
                                </div>
                                <div>
                                    <div class="yuan"></div>
                                    <div>{{item.tip3}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-for="(item,index) in ydClassList" class="yd-width ys-yd-list-end">
                        <div class="top-yd end-yd" :style="{ background: item.color }">
                            {{ item.num }} + MCN
                            <br>
                            短视频全实战
                        </div>
                        <div class="ys-yd-list-end-item">
                            <div class="flex-around">
                                <div class="flex">
                                    <div class="yuan"></div>
                                    <div>{{item.tip1}}</div>
                                </div>
                                <div class="flex two-tip">
                                    <div class="yuan"></div>
                                    <div>{{item.tip2}}</div>
                                </div>
                            </div>
                            <div class="flex-start">
                                <div class="tip3-box">
                                    <div class="yuan"></div>
                                    <div>{{item.tip3}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="big-title" id="shiherenqun">哪些人适合学短视频运营？</div>
                <img class="title-line" :data-src="titleLineBg">

                <div>
                    <div :class="{'na': true, 'flex': !ismobile, 'flex_b': !ismobile}">
                        <div v-for="(item, index) in suitableListPc" :key="item.pation"
                            :class="{ 'active': suitableIndex == index && !ismobile}" @mousemove="movesuitable(index)"
                            @mouseleave="leavesuitable">
                            <img :data-src="!ismobile ? item.imgurl : item.ydImgUrl">
                            <div class="name" v-if="!ismobile">
                                {{ item.pation }}
                            </div>
                            <div v-else class="pation-class">
                                {{ item.pation }}
                            </div>
                            <div class="info">
                                <div class="info-one">{{ item.name }}</div>
                                <div class="info-two">{{ismobile ? item.descYd: item.desc }}</div>
                                <div class="info-san">{{ismobile ? item.desc2Yd: item.desc2 }}</div>
                            </div>
                            <div class="btn" @click="kefu" v-if="!ismobile">
                                {{ item.btnText }}
                            </div>
                        </div>
                    </div>
                    <div class="naYd" v-if="ismobile">
                        <div v-for="(item, index) in suitableListMobileSecond" :key="item.pation"
                            :class="{ 'active': suitableIndex == index && !ismobile}" @mousemove="movesuitable(index)"
                            @mouseleave="leavesuitable">
                            <img :data-src="item.ydImgUrl">
                            <div class="pation-class">
                                {{ item.pation }}
                            </div>
                            <div class="info">
                                <div class="info-one">{{ item.name }}</div>
                                <div class="info-two">{{item.descYd}}</div>
                                <div class="info-san">{{item.desc2Yd }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!ismobile">
                <div class="big-title" id="kechengneirong">6大课程阶段 循序渐进详解运营技能</div>
                <img class="title-line title-lines" :data-src="titleLineBg">
                <div class="jd-con">
                    <!-- <div class="icon1" :style="{
				'background-image':
					'url(' +
					require('../../assets/videoImg/jd-icon2.png') +
					')',
			}">初识入门</div> -->
                    <div class="content flex flex_s">
                        <div class="jd-left">
                            <div @mousemove="movejieduan(index)" v-for="(item, index) in jieduan" :key="item.title"
                                :class="{ 'active': jieduanIndex == index,'jd-left-item': jieduanIndex != index}">
                                <img v-if="jieduanIndex != index" data-src="../../assets/videoImg/sanjiao.png" alt="">
                                <div class="sanjiao-title" :class="{ 'active-sanjiao-title': jieduanIndex == index}">{{
                                    item.title }}</div>
                            </div>
                        </div>
                        <div class="jd-right">
                            <div class="right-con flex flex_s">
                                <div class="content-left">
                                    <!-- <div class="title" style="font-size: 20px;">{{ jieduan[jieduanIndex].title }}</div> -->
                                    <div class="study">
                                        <div class="top flex flex_s">
                                            <img :data-src="studyIcon">
                                            <div>学习内容</div>
                                        </div>
                                        <div class="list stuList">
                                            <div v-for="(items, index) in jieduan[jieduanIndex].study" :key="items">
                                                <div class="yuan"></div>
                                                <div class="list-item">{{ items }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="jiuye">
                                        <div class="top flex flex_s">
                                            <img :data-src="studyIcon2">
                                            <div>掌握技能</div>
                                        </div>
                                        <div class="list">
                                            <div v-for="(items, index) in jieduan[jieduanIndex].jiuye" :key="items">
                                                <div class="yuan"></div>
                                                <div class="list-item">{{ items }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="content-right flex flex_c">
                                    <img :data-src="jieduan[jieduanIndex].imgurl">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="zhuliu-box">
                    <div>
                        <div class="jtbox">
                            <p>精通10+主流软件<br>零基础玩转短视频 </p>
                        </div>
                        <div v-for="(item,index) in jTList" :key="item.imgurl">
                            <img :data-src="item.imgurl" alt="" style="margin:30px 0 20px 0;">
                            <p style="margin-bottom:31px">{{item.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="six-class">
                <div>
                    <div class="big-title big-title-six">6大课程阶段 <br> 循序渐进详解运营技能</div>
                    <img class="title-line title-lines" :data-src="titleLineBg2">
                    <div v-for="(item, jieduanIndex) in jieduan">
                        <div class="six-box" :style="{height: item.height }">
                            <div class="six-left-box">
                                <img :data-src="ismobile ? jieduan[jieduanIndex].imgurlLeft : jieduan[jieduanIndex].imgurl"
                                    class="left-img">
                            </div>
                            <div class="right-logo">
                                <img :data-src="jieduan[jieduanIndex].imgurlyd">
                                <span class="right-logo-num">{{'0' + (jieduanIndex + 1)}}</span>
                            </div>
                            <div style="margin-top: 2.5vw;padding-bottom: 4.5vw;">
                                <div class="six-right-box-title">{{item.title}}</div>
                                <div
                                    :class="{'six-right-box-space': item.spaceContent, 'six-right-box-container': true,'six-right-box-space-onlyClass':item.onlyClass}">
                                    <div v-for="(items, index) in jieduan[jieduanIndex].study" :key="items"
                                        :class="{'six-right-box': !item.spaceContent, 'six-right-box-space-item': item.spaceContent,'onlyClass':item.onlyClass}">
                                        <div class="flex-start">
                                            <img :data-src="duigouImg" alt="" class="duigou">
                                            <div class="list-item" :class="{'onlyClass-list':item.onlyClass}">{{ items
                                                }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="big-box six-big-box">
                    <div v-if="ismobile" class="video-title-box six-big-title-box">
                        精通10+主流软件零基础玩转短视频
                    </div>
                    <div class="six-big-box-item">
                        <div v-for="(item,index) in jTList" :key="index">
                            <div>
                                <img :data-src="item.imgurl" alt="" class="dy-img">
                                <p class="title-bianxian">{{ item.name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 打造8大专业能力 用实力抢占职场C位 -->
            <div class="ys-con ys-cons" v-if="!ismobile">
                <div class="big-title big-titles">打造8大专业能力 用实力抢占职场C位</div>
                <img class="title-line title-line-ys" :data-src="titleLineBg2">
                <div class="zy-con flex flex_b">
                    <div v-for="(item, index) in zynl" :key="item.title" class="zy-item">
                        <div class="zy-info">
                            <div class="major-title">
                                {{ item.title }}
                            </div>
                            <div>
                                <img :data-src="item.imgurl">
                            </div>
                        </div>
                        <div class="desc">
                            {{ item.content }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="ys-con ys-cons" v-else>
                <div class="big-title big-titles" style="font-weight: blod;">打造8大专业能力<br> 用实力抢占职场C位</div>
                <img class="title-line title-line-ys" :data-src="titleLineBg">
                <div class="major-box-yd">
                    <div v-for="(item, index) in zynl" :key="item.title" class="zy-item"
                        style="width: 20.7vw;height: 22.6vw;">
                        <div class="work-box">
                            <div>
                                <img :data-src="ismobile ? item.imgurlyd : item.imgurl" style="width: 10.5vw;height:10vw;">
                            </div>
                            <div class="major-title-yd">
                                {{ item.titleyd }}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- 短视频就业岗位多 黄金薪职业 -->
            <div class="huangjin-box" v-if="!ismobile">
                <div class="top-title">短视频就业岗位多 黄金“薪”职业</div>
                <div class="center-box">
                    <div class="left-box">
                        <div class="left-title">
                            <span>短视频行业发展迅猛,</span>
                            <span>&emsp;市场规模不断扩大</span>
                        </div>
                        <div class="tu-box">
                            <div v-for="(item,index) in tuList" :key="item.desc">
                                <div class="tu-box-contain" :style="{
                                    'background': 'url(' + item.imgUrl + ') no-repeat center / cover'
                                }">
                                    <p v-if="item.showNum">{{item.num}}</p>
                                </div>
                                <p v-if="item.oneShow">
                                    <span class="tu-num">
                                        {{item.num}}
                                    </span>
                                    <span class="tu-desc">
                                        {{item.desc}}
                                    </span>
                                </p>
                                <div v-else>
                                    <p class="tu-num">{{item.num}}</p>
                                    <p class="tu-desc">{{item.desc}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left-box right-box">
                        <div class="left-title right-title">
                            <span>2024年短视频运营端</span>
                            <span>人才缺口达400万</span>
                        </div>
                        <div class="flex qukou-box">
                            <div v-for="(item,index) in qukoList" :key="item.name">
                                <div class="qukou-box-contain" :style="{
                                    'background': 'url(' + item.imgUrl + ') no-repeat center / cover'
                                }">
                                    <div class="qukobj">
                                        <p>{{item.name}}</p>
                                        <p class="qukonum">{{item.num}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-explain">
                    * 数据来源职友集及中国互联网络信息中心 (CNNIC) 在京发布第 54 次《中国互联网络发展状况统计报告》
                </div>
            </div>
            <div class="huangjin-box-yd" v-else>
                <div style="position: relative;">
                    <div class="huangjin-box-yd-top">
                        <div class="big-title big-title-six huangjin-box-yd-title">「短视频就业岗位多 黄金“薪”职业」</div>
                    </div>
                    <div style="position: absolute;top: 15.6vw;padding: 0 4.5vw;display: grid; grid-template-columns: repeat(2, 1fr);
                    gap: 5.1vw 3.5vw;">
                        <div v-for="(item,index) in tuYdList" :key="item.num">
                            <div style="background-color:#fff;width: 43.7vw;border-radius: 2.1vw">
                                <div style="margin-bottom:5vw">
                                    <img :data-src="item.imgUrl" alt="" style="width:39.7vw;height:24vw;margin-top: 1.7vw">
                                </div>
                                <div style="position: relative;">
                                    <div class="tuYd-title-box">
                                        <p class="tuYd-title">{{item.title}}</p>
                                    </div>
                                    <div class="tuYd-content" :style="{ background: item.color }">
                                        <p class="tuYd-num">{{item.num}}</p>
                                        <p class="tuYd-num tuYd-desc"> {{item.desc}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-explain tuYdtip" v-if="ismobile">
                *数据来源于职友集等招聘网站，仅作为信息展示，不作为效果承诺
            </div>
            <bottom_public_new v-if="!ismobile"></bottom_public_new>
            <bottom_public_new_yd v-else :bgcolor="bgcolor"></bottom_public_new_yd>
        </div>
</template>

<script>
    import Swiper from "swiper";
    import "swiper/css/swiper.min.css";
    import "swiper/js/swiper.min.js";
    import bottom_public_new from "../../components/bottom_public_new/bottom_public_new";
    import bottom_public_new_yd from "../../components/bottom_public_new_yd/bottom_public_new_yd";
    import header_public from "../../components/header_public/header_public";
    import header_public_yd from "../../components/header_public_yd/header_public_yd";
    export default {
        metaInfo: {
            title: "【积云教育官网】短视频运营|直播带货|Python培训|数字技术培训机构",
            meta: [{
                name: "description",
                content: "积云教育,IT职业教育领导品牌.以促进稳定高薪就业为导向,开设AI短视频大师班,人工智能Python,AI数字媒体,国际数字人才专修班等热门IT课程.积云教育分校遍布全国,坚持精品课程研发,口碑好,学员及家长认可的数字媒体培训机构.",
            },
            {
                name: "keywords",
                content: "积云教育,IT培训,短视频运营,Python培训,直播带货,数字技术培训机构",
            },
            ],
        },
        components: {
            bottom_public_new,
            bottom_public_new_yd,
            header_public,
            header_public_yd
        },
        computed: {
            suitableListMobileSecond() {
                return this.suitableListTotal.slice(3, 5);
            },
            suitableListPc() {
                return this.innerWidth <= 768 ? this.suitableListTotal.slice(0, 3) : this.suitableListTotal;
            },
            classList() {
                return this.innerWidth <= 768 ? this.classListTotal.slice(0, 4) : this.classListTotal;
            },
            themeList() {
                return this.innerWidth <= 768 ? this.themeListTotal.slice(0, 4) : this.themeListTotal;
            },
            themeList2() {
                return this.innerWidth <= 768 ? this.themeListTotal2.slice(0, 4) : this.themeListTotal2;
            },
        },
        data() {
            return {
                isCollapse: false,
                innerWidth: 0,
                ismobile: false,
                headerColor: '#010C27',
                bg1: require('../../assets/videoImg/ysBanner.png'),
                bannerBgPC:'https://jyoa-1300845702.cos.ap-beijing.myqcloud.com/uploads/20250118/9b00830573e9fdcba2366cf81e754588.png',
                bannerBgYD: 'https://jyoa-1300845702.cos.ap-beijing.myqcloud.com/uploads/20250118/2ca440ba89c624897514ac944852f2a7.png',
                titleLineBg:require('../../assets/videoImg/title-line.png'),
                titleLineBg2:require('../../assets/videoImg/title-line2.png'),
                studyIcon:require('../../assets/videoImg/xx-icon2.png'),
                studyIcon2:require('../../assets/videoImg/xx-icon1.png'),
                duigouImg:require('../../assets/videoImg/duigou.png'),
                tabIndex: 0,
                tabList: [{
                    name: '首页',
                    path: '',
                    scrollIntoViewID: 'header'
                }, {
                    name: '适合人群',
                    path: '',
                    scrollIntoViewID: 'shiherenqun'
                }, {
                    name: '课程内容',
                    path: '',
                    scrollIntoViewID: 'kechengneirong'
                }, {
                    name: '讲师团队',
                    path: '',
                    scrollIntoViewID: 'jiangshituandui'
                }, {
                    name: '学员作品',
                    path: '',
                    scrollIntoViewID: 'xueyuanzuopin'
                }, {
                    name: '就业服务',
                    path: '',
                    scrollIntoViewID: 'jiuyefuwu'
                }, {
                    name: '联系我们',
                    path: '',
                    scrollIntoViewID: 'lianxiwomen'
                }],
                aboutContent: '北京优思安科技有限公司，致力于打造中国创新创业职业教育实训基地，打造学员价值、提高企业价值、创造社会价值。为国家和全社会经济发展提供强有力的人才支撑',
                bgcolor: 'linear-gradient(68deg, #F37425, #FA9650)',
                suitableIndex: 0,
                suitableList2: [],
                suitableList: [],
                suitableListTotal: [{
                    name: "想高薪就业",
                    desc: "快速入门主流行业",
                    desc2: "收入不受限",
                    descYd: "就职主流行业",
                    desc2Yd: "收入不受限",
                    pation: "应届毕业生",
                    btnText: "立即获取入行秘籍",
                    ydImgUrl: require('../../assets/videoImg/na1-yd.png'),
                    imgurl: require('../../assets/videoImg/na1.png')
                }, {
                    name: "想流量变现",
                    desc: "利用平台打造个人",
                    desc2: "IP,开启商业模式",
                    descYd: '利用平台开启',
                    desc2Yd: '开启商业模式',
                    pation: "自由工作者",
                    btnText: "一键匹配职业规划",
                    ydImgUrl: require('../../assets/videoImg/na2-yd.png'),
                    imgurl: require('../../assets/videoImg/na2.png')
                }, {
                    name: "想在家赚钱",
                    desc: "育儿类垂直内容",
                    desc2: "具备天然优势",
                    descYd: '育儿类内容',
                    desc2Yd: '打造爆款短视频',
                    pation: "全职宝妈",
                    btnText: "一键了解运营攻略",
                    ydImgUrl: require('../../assets/videoImg/na3-yd.png'),
                    imgurl: require('../../assets/videoImg/na3.png')
                }, {
                    name: "想转行互联网",
                    desc: "不用写代码,零基础入",
                    desc2: "行短视频轻松拿高薪",
                    descYd: '不用写代码',
                    desc2Yd: '入行短视频轻松拿高薪',
                    pation: "代码无兴趣人员",
                    btnText: "0基础轻松入门",
                    ydImgUrl: require('../../assets/videoImg/na4-yd.png'),
                    imgurl: require('../../assets/videoImg/na4.png')
                }, {
                    name: "想兼职副业",
                    desc: "轻松开启短视频副",
                    desc2: "业,增加额外收入",
                    descYd: '轻松开启短视频副业',
                    desc2Yd: '增加额外收入',
                    pation: "短视频爱好者",
                    btnText: "立即申请试听课程",
                    ydImgUrl: require('../../assets/videoImg/na5-yd.png'),
                    imgurl: require('../../assets/videoImg/na5.png')
                }],
                zynl: [{
                    imgurl: require('../../assets/videoImg/zy-icon1.png'),
                    imgurlyd: require('../../assets/videoImg/zy-icon-yd1.png'),
                    content: "根据短视频账号定位快速策划选题，产出优质的创意短视频内容",
                    title: '短视频策划',
                    titleyd: '视频策划',
                }, {
                    imgurl: require('../../assets/videoImg/zy-icon2.png'),
                    imgurlyd: require('../../assets/videoImg/zy-icon-yd2.png'),
                    content: "现场调度组织拍摄，快速高效跟进后期制作，保证视频质量",
                    title: '短视频导演',
                    titleyd: '视频导演',
                }, {
                    imgurl: require('../../assets/videoImg/zy-icon3.png'),
                    imgurlyd: require('../../assets/videoImg/zy-icon-yd3.png'),
                    content: "掌握摄像设备的使用及拍摄技巧，满足各类短视频的前期拍摄需求",
                    title: '短视频拍摄',
                    titleyd: '视频拍摄',
                }, {
                    imgurl: require('../../assets/videoImg/zy-icon4.png'),
                    imgurlyd: require('../../assets/videoImg/zy-icon-yd4.png'),
                    content: "掌握12大剪辑技巧，熟练驾驭各类型短视频的后期剪辑风格",
                    title: '短视频剪辑',
                    titleyd: '视频剪辑',
                }, {
                    imgurl: require('../../assets/videoImg/zy-icon5.png'),
                    imgurlyd: require('../../assets/videoImg/zy-icon-yd5.png'),
                    content: "掌握长短视频片头片尾、特效及动效包装技能，让视频锦上添花",
                    title: '短视频包装',
                    titleyd: '视频包装',
                }, {
                    imgurl: require('../../assets/videoImg/zy-icon6.png'),
                    imgurlyd: require('../../assets/videoImg/zy-icon-yd6.png'),
                    content: "熟悉短视频爆款打造逻辑及平台规则，掌握吸粉运营技巧及变现定位",
                    title: '短视频运营',
                    titleyd: '视频运营',
                }, {
                    imgurl: require('../../assets/videoImg/zy-icon7.png'),
                    imgurlyd: require('../../assets/videoImg/zy-icon-yd7.png'),
                    content: "掌握各类直播间搭建方法、直播团队分工定位、直播导流及数据分析",
                    title: '短视频直播',
                    titleyd: '视频直播',
                }, {
                    imgurl: require('../../assets/videoImg/zy-icon8.png'),
                    imgurlyd: require('../../assets/videoImg/zy-icon-yd8.png'),
                    content: "熟悉各类短视频账号的变现思路及落地方法，既引来流量又产生价值",
                    title: '短视频变现',
                    titleyd: '视频变现',
                }],
                jieduanIndex: 0,
                jieduan: [
                    {
                        title: "初识入门",
                        imgurl: require('../../assets/videoImg/1.png'),
                        imgurlLeft: require('../../assets/videoImg/leftBg.png'),
                        imgurlyd: require('../../assets/videoImg/icon-yellow.png'),
                        study: ['运营行业认知', '运营必备平面作图技能、 AI制图', '运营必备基础剪辑技能、手机摄影'],
                        jiuye: ['审美和基础必备技能培养'],
                        height: '29vw',
                        spaceContent: false,
                    },
                    {
                        title: "剪辑进阶",
                        imgurl: require('../../assets/videoImg/2.png'),
                        imgurlLeft: require('../../assets/videoImg/leftBg2.png'),
                        imgurlyd: require('../../assets/videoImg/icon-green.png'),
                        study: ['PR软件基础', '各类商业口播项目、信息流项目剪辑训练', '剪辑思维训练、高级剪辑'],
                        jiuye: ['高级剪辑技能培养'],
                        height: '29vw',
                        spaceContent: false,
                    }, {
                        // 3
                        title: "拍摄进阶",
                        imgurl: require('../../assets/videoImg/3.png'),
                        imgurlLeft: require('../../assets/videoImg/leftBg3.png'),
                        imgurlyd: require('../../assets/videoImg/icon-pink.png'),
                        study: ['摄影基础', '设备使用实操', '各类项目拍摄实操'],
                        jiuye: ['常见视频类型拍摄和后期制作能力培养'],
                        height: '23vw',
                        spaceContent: false,
                    }, {
                        // 4
                        title: "短视频运营和编导",
                        imgurl: require('../../assets/videoImg/4.png'),
                        imgurlLeft: require('../../assets/videoImg/leftBg4.png'),
                        imgurlyd: require('../../assets/videoImg/icon-yellow.png'),
                        study: ['抖音运营基础', '爆款短视频内容创意策划', '短视频运营策略划', '项目孵化实战'],
                        jiuye: ['爆款短视频制作和运营策略培养'],
                        height: '23vw',
                        spaceContent: true,
                        onlyClass: true,
                    }, {
                        // 5
                        title: "高级短视频制作",
                        imgurl: require('../../assets/videoImg/5.png'),
                        imgurlLeft: require('../../assets/videoImg/leftBg5.png'),
                        imgurlyd: require('../../assets/videoImg/icon-green.png'),
                        study: ['动画基础', '短视频高级调色', '特效基础', '电商品牌项目孵化实战'],
                        jiuye: ['短视频后期特效和电商品牌孵化能力培养'],
                        height: '23vw',
                        spaceContent: true,
                    }, {
                        // 6
                        title: "运营高级进阶",
                        imgurl: require('../../assets/videoImg/6.png'),
                        imgurlLeft: require('../../assets/videoImg/leftBg6.png'),
                        imgurlyd: require('../../assets/videoImg/icon-pink.png'),
                        study: ['直播运营基础', '直播实战演练', '数据运营和直播策划', 'AI数字人直播'],
                        jiuye: ['直播带货和AI数字人直播技术培养'],
                        height: '23vw',
                        spaceContent: true,
                        onlyClass: true,
                    }
                ],
                jTList: [
                    {
                        name: "Photoshop",
                        imgurl: require('../../assets/videoImg/ps.png')
                    },
                    {
                        name: "Photoshop",
                        imgurl: require('../../assets/videoImg/pr.png')
                    },
                    {
                        name: "After Effects",
                        imgurl: require('../../assets/videoImg/ae.png')
                    },
                    {
                        name: "剪映APP",
                        imgurl: require('../../assets/videoImg/jy.png')
                    },
                    {
                        name: "Illustrator",
                        imgurl: require('../../assets/videoImg/ai.png')
                    },
                    {
                        name: "Cinema 4D",
                        imgurl: require('../../assets/videoImg/chena.png')
                    },
                    {
                        name: "巨量星图",
                        imgurl: require('../../assets/videoImg/fs.png')
                    },
                    {
                        name: "巨量引擎",
                        imgurl: require('../../assets/videoImg/jxyq.png')
                    },
                    {
                        name: "巨量千川",
                        imgurl: require('../../assets/videoImg/jlqc.png')
                    },
                    {
                        name: "DOU+",
                        imgurl: require('../../assets/videoImg/dou.png')
                    },
                ],
                bianxianList: [
                    {
                        name: '抖音',
                        tip: '带货/广告/平台活动',
                        tip2: '流量分成/KOL/KOC',
                        imgurl: require('../../assets/videoImg/dy.png'),
                    },
                    {
                        name: '快手',
                        tip: '广告/平台活动/直播',
                        tip2: '电商/KOL/KOC',
                        imgurl: require('../../assets/videoImg/ks.png'),
                    },
                    {
                        name: '小红书',
                        tip: '广告/平台活动',
                        tip2: '私域引流/种草带货',
                        imgurl: require('../../assets/videoImg/xhs.png'),
                    },
                    {
                        name: 'B站',
                        tip: '广告/平台活动',
                        tip2: '签约UP主/KOL',
                        imgurl: require('../../assets/videoImg/bili.png'),
                    },
                    {
                        name: '视频号',
                        tip: '平台活动/直播',
                        tip2: '电商/KOL',
                        imgurl: require('../../assets/videoImg/bx.png'),
                    },
                ],
                themeListTotal: [
                    {
                        theme: '好物种草',
                        showYellow: true,
                        imgUrl: require('../../assets/videoImg/bg1.png'),
                        imgUrlYD: require('../../assets/videoImg/bgyd1.png'),
                    },
                    {
                        theme: '家居生活',
                        showYellow: false,
                        imgUrl: require('../../assets/videoImg/bg2.png'),
                        imgUrlYD: require('../../assets/videoImg/bgyd2.png'),
                    },
                    {
                        theme: '剧情短剧',
                        showYellow: true,
                        imgUrl: require('../../assets/videoImg/bg3.png'),
                        imgUrlYD: require('../../assets/videoImg/bgyd3.png'),
                    },
                    {
                        theme: '美妆美食',
                        showYellow: false,
                        imgUrl: require('../../assets/videoImg/bg4.png'),
                        imgUrlYD: require('../../assets/videoImg/bgyd4.png'),
                    },
                    {
                        theme: '影视解说',
                        showYellow: true,
                        imgUrl: require('../../assets/videoImg/bg5.png'),
                    },
                ],
                themeListTotal2: [
                    {
                        theme: '产品测评',
                        showYellow: false,
                        imgUrl: require('../../assets/videoImg/bg6.png'),
                        imgUrlYD: require('../../assets/videoImg/bgyd5.png'),
                    },
                    {
                        theme: '健身运动',
                        showYellow: true,
                        imgUrl: require('../../assets/videoImg/bg7.png'),
                        imgUrlYD: require('../../assets/videoImg/bgyd6.png'),
                    },
                    {
                        theme: '母婴产品',
                        showYellow: false,
                        imgUrl: require('../../assets/videoImg/bg8.png'),
                        imgUrlYD: require('../../assets/videoImg/bgyd7.png'),
                    },
                    {
                        theme: '游戏主播',
                        showYellow: true,
                        imgUrl: require('../../assets/videoImg/bg9.png'),
                        imgUrlYD: require('../../assets/videoImg/bgyd8.png'),
                    },
                    {
                        theme: '数码电子',
                        showYellow: false,
                        imgUrl: require('../../assets/videoImg/bg10.png'),
                    },
                ],
                classListTotal: [
                    {
                        num: '10',
                        title: '热门平台全囊括',
                        imgUrl: require('../../assets/videoImg/classBg1.png'),
                        tip1: '从0到1搭建账号体系',
                        tip2: '专业指导+职场赋能',
                        tip3: '拿捏短视频潮流玩法',
                        color: 'linear-gradient(68deg, #6b23f4, #9b6cf7)',
                    },
                    {
                        num: '10',
                        title: '主流软件全掌握',
                        imgUrl: require('../../assets/videoImg/classBg2.png'),
                        tip1: '掌握主流剪辑等软件',
                        tip2: '培养全方位技术能力',
                        tip3: '成就职场“薪”人才',
                        color: 'linear-gradient(68deg, #59C0B8, #83D2CC)',
                    },
                    {
                        num: '14',
                        title: '项目类型全覆盖',
                        imgUrl: require('../../assets/videoImg/classBg3.png'),
                        tip1: '480小时高强度训练',
                        tip2: '场景式项目实战教学',
                        tip3: '积累高质量项目经验',
                        color: 'linear-gradient(68deg, #ED7451, #F09562)',
                    },
                    {
                        num: '16',
                        title: '核心技能全学习',
                        imgUrl: require('../../assets/videoImg/classBg4.png'),
                        tip1: '策划/拍摄/后期/运营',
                        tip2: '0基础运营到流量变现',
                        tip3: '满足10+热门行业需求',
                        color: 'linear-gradient(68deg, #4D72F6, #7B99FE)',
                    },
                    {
                        num: '100',
                        title: 'MCN短视频全实战',
                        imgUrl: require('../../assets/videoImg/classBg5.png'),
                        tip1: '优质成片剪辑输出',
                        tip2: '超级实战项目库',
                        tip3: '100%覆盖企业需求',
                        color: 'linear-gradient(68deg, #B157F4, #D973F5)',
                    },
                ],
                ydClassList: [
                    {
                        num: '100',
                        title: 'MCN短视频全实战',
                        imgUrl: require('../../assets/videoImg/classBg5.png'),
                        tip1: '优质成片剪辑输出',
                        tip2: '超级实战项目库',
                        tip3: '100%覆盖企业需求',
                        color: 'linear-gradient(68deg, #B157F4, #D973F5)',
                    },
                ],
                tuList: [
                    {
                        imgUrl: require('../../assets/videoImg/tu1.png'),
                        num: '10.99亿',
                        desc: '网民',
                        oneShow: true,
                        showNum: true,
                    },
                    {
                        imgUrl: require('../../assets/videoImg/tu2.png'),
                        num: '10.90亿',
                        desc: '短视频用户',
                        oneShow: true,
                        showNum: true,
                    },
                    {
                        imgUrl: require('../../assets/videoImg/tu3.png'),
                        num: '7.77亿',
                        desc: '网络直播用户',
                        oneShow: false,
                        showNum: true,
                    },
                    {
                        imgUrl: require('../../assets/videoImg/tu4.png'),
                        num: '10660.8亿',
                        desc: '2025年短视频市场',
                        oneShow: false,
                        showNum: false,
                    },
                ],
                tuYdList: [
                    {
                        imgUrl: require('../../assets/videoImg/bottom-img1.png'),
                        num: '￥17.5K',
                        desc: '月平均薪资',
                        title: '短视频运营师',
                        color: 'linear-gradient(68deg, #4C72F6, #7E9CFF)',
                    },
                    {
                        imgUrl: require('../../assets/videoImg/bottom-img2.png'),
                        num: '￥14.3K',
                        desc: '月平均薪资',
                        title: '直播运营师',
                        color: 'linear-gradient(68deg, #ED6F4F, #F09C65)',
                    },
                    {
                        imgUrl: require('../../assets/videoImg/bottom-img3.png'),
                        num: '￥20.7K',
                        desc: '月平均薪资',
                        title: '自媒体网红',
                        color: 'linear-gradient(68deg, #58BFB7, #88D4CF)',
                    },
                    {
                        imgUrl: require('../../assets/videoImg/bottom-img4.png'),
                        num: '￥15.3K',
                        desc: '月平均薪资',
                        title: '短视频导演',
                        color: 'linear-gradient(68deg, #6A23F4, #9C6CF7)',
                    },
                ],
                qukoList: [
                    {
                        name: '短视频剪辑师',
                        num: '￥13.8K',
                        imgUrl: require('../../assets/videoImg/quekoBg.png')
                    },
                    {
                        name: '短视频运营师',
                        num: '￥18.8K',
                        imgUrl: require('../../assets/videoImg/quekoBg.png')
                    },
                    {
                        name: '直播运营师',
                        num: '￥15.4K',
                        imgUrl: require('../../assets/videoImg/quekoBg.png')
                    },
                    {
                        name: '自媒体网红',
                        num: '￥17.5K',
                        imgUrl: require('../../assets/videoImg/quekoBg.png')
                    },
                    {
                        name: '影视特效合成师',
                        num: '￥12.8K',
                        imgUrl: require('../../assets/videoImg/quekoBg.png')
                    },
                    {
                        name: '短视频导演 / 编导',
                        num: '￥14.3K',
                        imgUrl: require('../../assets/videoImg/quekoBg.png')
                    },
                    {
                        name: '视频广告制作师',
                        num: '￥12.9K',
                        imgUrl: require('../../assets/videoImg/quekoBg.png')
                    },
                    {
                        name: '高级视频摄影师',
                        num: '￥15.0K',
                        imgUrl: require('../../assets/videoImg/quekoBg.png')
                    },
                    {
                        name: '后期调色师',
                        num: '￥18K',
                        imgUrl: require('../../assets/videoImg/quekoBg.png')
                    },
                ],
            }
        },
        created() {
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        },
        mounted() {
            this.innerWidth = window.innerWidth
            this.ismobile = this.innerWidth <= 768 ? true : false
            window.addEventListener("resize", this.handleResize);
            setTimeout(() => {
                this.initLazyLoading()
            }, 0);
        },
        methods: {
            initLazyLoading() {
                this.initImgLoading()
                this.initBgLoading()
            },
            initImgLoading(){
                const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            this.loadImage(entry.target, observer);
                        }
                    });
                }, {
                    rootMargin: '50px',
                    threshold: 0.1
                });
                document.querySelectorAll('img').forEach(img => {
                    observer.observe(img);
                });
            },
            loadImage(img, observer) {
                const src = img.getAttribute('data-src');
                if (src) {
                    img.src = src;
                    // alert(1)
                    img.onload = () => {
                        observer.unobserve(img);
                    };
                }
            },
            initBgLoading(){
                const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            this.loadBg(entry.target, observer);
                        }
                    });
                }, {
                    rootMargin: '50px',
                    threshold: 0.1
                });
                document.querySelectorAll('.lazy-bg').forEach(img => {
                    observer.observe(img);
                });
            },
            loadBg(img, observer) {
                let src;
                if(this.isPC()){
                    src = img.getAttribute('data-bg');
                }else{
                    src = img.getAttribute('data-bg-m');
                }
                if (src) {
                    img.style.setProperty('background-image', `url(${window.location.origin + src})`, 'important');
                    observer.unobserve(img);
                }
            },
            addClose(value) {
                this.isCollapse = value
            },
            handleResize() {
                this.innerWidth = window.innerWidth
                this.ismobile = this.innerWidth <= 768 ? true : false
            },
            movesuitable(i) {
                this.suitableIndex = i
            },
            leavesuitable() {
                this.suitableIndex = 0
            },
            kefu() {
                window.mantis.requestChat()
            },
            movejieduan(i) {
                this.jieduanIndex = i
            },
        }
    }
</script>


<style scoped src="../../assets/css/dsp/dsp_pc.css"></style>