<template lang="">
    <div>
        <div class="top-box">
            <div class="flex leftbox" >
                <img src="../../assets/videoImg/ydmenu.png" alt="" class="ydmenu-img" @click="openMenu">
                <span @click="openMenu">
                    咨询热线: 400-800-2320
                </span>
            </div>
            <img src="../../assets/videoImg/jy-logo.png" alt="" class="jylogo">
        </div>
        <div v-if="isCollapse">
            <div class="ydmenu-box">
                <div v-for="(item, index) in navList" :key="index" :class="navindex == index ? 'active' : 'noactive'">
                    <router-link :to="item.path" @click.native="setIsCollapse(false)">{{ item.name }}</router-link>
                </div>
            </div>
        </div>
        <div v-if="isCollapse" class="overlay" @click="isCollapse=false"></div>
    </div>
</template>
<script>
    export default {
        props: ["navindex"],
        data() {
            return {
                isCollapse: false,
                navList: [
                    {
                        name: "首页",
                        path: "/",
                    },
                    {
                        name: "AI短视频大师班",
                        path: "/short_video",
                    },
                    {
                        name: "人工智能",
                        path: "/intelligence",
                    },
                    {
                        name: "AI数字媒体",
                        path: "/media",
                    },
                    {
                        name: "国际数字人才专修班",
                        path: "",
                    },
                    {
                        name: "关于我们",
                        path: "",
                    },
                ],
            }
        },
        mounted() {
           
        },
        methods: {
            setIsCollapse(value) {
                this.isCollapse = value;
            },
            openMenu(key, keyPath) {
                this.isCollapse = !this.isCollapse
                this.$emit('addClose', this.isCollapse)
            },
        },
    }   
</script>
<style scoped>
    .top-box {
        background: #fff;
        height: 13vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 4.5vw;
    }

    .ydmenu-img {
        width: 4.5vw;
        height: 3.2vw;
        margin-right: 3.2vw;
    }

    .jylogo {
        width: 25vw;
        height: 5.2vw;
    }

    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ydmenu-box {
        width: 54vw;
        height: 100vh;
        background: #fff !important;
        position: fixed;
        /* 固定定位 */
        top: 12vw;
        /* 距离顶部的距离，可以根据需要调整 */
        left: 0;
        right: 0;
        z-index: 999;
        /* 确保在其他内容之上 */
    }

    .ydmenu-box>div {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #D2D2D2;
        margin-left: 10vw;
    }

    .ydmenu-box a {
        text-decoration: none;
        font-family: SourceHanSansSC, SourceHanSansSC;
        font-weight: 400;
        font-size: 4vw;
        color: #232324;
        line-height: 16vw;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .ydmenu-box .active .a {
        color: #fff !important;
    }

    /* 蒙层样式 */
    .overlay {
        position: fixed;
        top: 13vw;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        /* 半透明黑色背景 */
        z-index: 998;
        /* 确保在 ydmenu-box 之下，但在其他内容之上 */
    }
</style>