<template>
  <div class="index_box">
    <header_public_yd  :navindex='0' @addClose="addClose" class="fixed-header"></header_public_yd>
    <!-- 轮播图 -->
    <div class="index_first swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<img :data-src="bannerOne" alt="积云教育" loading="lazy"/>
				</div>
        <div class="swiper-slide">
					<img :data-src="bannerTwo" alt="积云教育" loading="lazy"/>
				</div>
        <div class="swiper-slide">
					<img :data-src="bannerThree" alt="积云教育" loading="lazy"/>
				</div>
			</div>
			<div class="swiper-pagination"></div>
    </div>
    <!-- 单张 -->
    <!-- <div class="index_first">
      <img src="../assets/image/index/banner.jpg" alt="积云教育" />
    </div> -->
    <div class="index_second">
      <h1 class="h1_title">行业热门数字技术课程</h1>
      <h2 class="h2_des">针对不同基础学员量身打造</h2>
      <div class="line">
        <div class="line_dot"></div>
      </div>
      <div class="index_sec_course">
        <div class="index_sec_course_item">
          <img @click="toshipin" :data-src="indexCourse2" alt />
          <div class="index_sec_course_text">
            <h3 class="h3_title">AI短视频大师班</h3>
            <p class="h3_des">
              <span class="h3_des_item" @click="advice()">报名条件</span>
              <span class="h3_des_item" @click="advice()">学多久</span>
              <span class="h3_des_item" @click="advice()">学费多少</span>
            </p>
          </div>
        </div>

   
        <div class="index_sec_course_item">
          <img @click="torengongzhineng" :data-src="indexCourse4" alt />
          <div class="index_sec_course_text">
            <h3 class="h3_title">人工智能</h3>
            <p class="h3_des">
              <span class="h3_des_item" @click="advice()">报名条件</span>
              <span class="h3_des_item" @click="advice()">学多久</span>
              <span class="h3_des_item" @click="advice()">学费多少</span>
            </p>
          </div>
        </div>

        <div class="index_sec_course_item">
          <img @click="todashuju" :data-src="AILogo" alt />
          <div class="index_sec_course_text">
            <h3 class="h3_title">AI数字媒体</h3>
            <p class="h3_des">
              <span class="h3_des_item" @click="advice()">报名条件</span>
              <span class="h3_des_item" @click="advice()">学多久</span>
              <span class="h3_des_item" @click="advice()">学费多少</span>
            </p>
          </div>
        </div>
        <!-- <div class="index_sec_course_item">
          <img @click="toquanzhan" src="../assets/image/index/index_course05.jpg" alt />
          <div class="index_sec_course_text">
            <h3 class="h3_title">全栈开发</h3>
            <p class="h3_des">
              <span class="h3_des_item" @click="advice()">报名条件</span>
              <span class="h3_des_item" @click="advice()">学多久</span>
              <span class="h3_des_item" @click="advice()">学费多少</span>
            </p>
          </div>
        </div>-->
        <div class="index_sec_course_item">
          <img :data-src="numberLogo" alt />
          <div class="index_sec_course_text">
            <h3 class="h3_title">国际数字人才专修班</h3>
            <p class="h3_des">
              <span class="h3_des_item" @click="advice()">报名条件</span>
              <span class="h3_des_item" @click="advice()">学多久</span>
              <span class="h3_des_item" @click="advice()">学费多少</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="index_third">
      <h1 class="h1_title">培训费用多少钱评估一下</h1>
      <h2 class="h2_des">根据个人情况，评估下学习费用，心里有底</h2>
      <div class="line">
        <div class="line_dot"></div>
      </div>
      <div class="index_third_box">
        <div class="index_third_box_item">
          <p>
            <img :data-src="indexDot" alt />
            <span>请选择您的状态</span>
          </p>
          <ul class="index_third_box_item_course">
            <li
              v-for="(item, index) in statusList"
              :key="index"
              @click="handleClickStatus(item, index)"
            >
              <div :class="statusIndex == index ? 'isCheckStutes' : 'checkStutes'"></div>
              <span :class="statusIndex == index ? 'active' : ''">{{ item }}</span>
            </li>
            <li></li>
          </ul>
        </div>
        <div class="index_third_box_item_line"></div>
        <div class="index_third_box_item">
          <p>
            <img :data-src="indexDot" alt />
            <span>请选择您的年龄</span>
          </p>
          <ul class="index_third_box_item_agebox">
            <li v-for="(item, index) in ageList" :key="index" @click="handleClickAge(item, index)">
              <div :class="ageIndex == index ? 'isCheckStutes' : 'checkStutes'"></div>
              <span :class="ageIndex == index ? 'active' : ''">{{ item }}</span>
            </li>
          </ul>
        </div>
        <div class="index_third_input">
          <img :data-src="mobileImg" />
          <input class="form_input" type="text" v-model="inputVal" placeholder="输入手机号 评估学习费用" />
        </div>
        <div class="index_third_btn" @click="handleClickFree">评估一下</div>
      </div>
    </div>
    <div class="index_fourth">
      <h1 class="h1_title">CBE教学模式 以能力培养为中心</h1>
      <h2 class="h2_des">针对不同基础学员量身打造</h2>
      <div class="line">
        <div class="line_dot"></div>
      </div>
      <img class="index_fourth_img" :data-src="indexFourthTop" />
      <div class="index_fourth_list">
        <div class="index_fourth_list_item">
          <p>以实际能力为基础</p>
          <div class="index_fourth_line_box">
            <div class="index_fourth_line"></div>
            <div class="index_fourth_line_bg"></div>
            <img :data-src="indexFourth1" />
          </div>
          <p>以学科知识为基础</p>
        </div>
        <div class="index_fourth_list_item">
          <p>以实际表现为依据</p>
          <div class="index_fourth_line_box">
            <div class="index_fourth_line"></div>
            <div class="index_fourth_line_bg"></div>
            <img :data-src="indexFourth2" />
          </div>
          <p>以学制教学为依据</p>
        </div>
        <div class="index_fourth_list_item">
          <p>信息反馈及时</p>
          <div class="index_fourth_line_box">
            <div class="index_fourth_line"></div>
            <div class="index_fourth_line_bg"></div>
            <img :data-src="indexFourth3" />
          </div>
          <p>信息反馈滞后</p>
        </div>
        <div class="index_fourth_list_item">
          <p>学习包/互联网</p>
          <div class="index_fourth_line_box">
            <div class="index_fourth_line"></div>
            <div class="index_fourth_line_bg"></div>
            <img :data-src="indexFourth4" />
          </div>
          <p>课本知识</p>
        </div>
        <div class="index_fourth_list_item">
          <p>讲师指导 学生实战</p>
          <div class="index_fourth_line_box">
            <div class="index_fourth_line"></div>
            <div class="index_fourth_line_bg"></div>
            <img :data-src="indexFourth5" />
          </div>
          <p>理论教学/实验验证</p>
        </div>
        <div class="index_fourth_list_item">
          <p>因材施教</p>
          <div class="index_fourth_line_box">
            <div class="index_fourth_line"></div>
            <div class="index_fourth_line_bg"></div>
            <img :data-src="indexFourth6" />
          </div>
          <p>共性目标</p>
        </div>
        <div class="index_fourth_list_item">
          <p>职业综合能力标准</p>
          <div class="index_fourth_line_box">
            <div class="index_fourth_line"></div>
            <div class="index_fourth_line_bg"></div>
            <img :data-src="indexFourth7" />
          </div>
          <p>大纲规定的标准</p>
        </div>
        <div class="index_fourth_list_item">
          <p>
            讲师全程跟进
            <br />为学生制定学习计划
          </p>
          <div class="index_fourth_line_box">
            <div class="index_fourth_line"></div>
            <div class="index_fourth_line_bg"></div>
            <img :data-src="indexFourth8" />
          </div>
          <p>
            传道/授业/解惑
            <br />改作业/考试
          </p>
        </div>
        <div class="index_fourth_list_item">
          <p>学生主动学习</p>
          <div class="index_fourth_line_box">
            <div class="index_fourth_line"></div>
            <div class="index_fourth_line_bg"></div>
            <img :data-src="indexFourth9" />
          </div>
          <p>学生被动学习</p>
        </div>
      </div>
    </div>
    <div class="index_five">
      <div class="index_five_title">
        <img :data-src="index_five_bg" />
      </div>
      <div class="index_five_img">
        <div class="cirImg"></div>
        <div class="centertriImg"></div>
        <div class="centerImg"></div>
      </div>
    </div>
    <div class="index_btn" @click="advice()">了解详情</div>
    <div class="index_seventh">
      <h1 class="h1_title">拥有好技术 成就好未来</h1>
      <div class="line">
        <div class="line_dot"></div>
      </div>
      <div class="index_seventh_img">
        <!-- <img src="../assets/image/index/index_seventh_money.png" /> -->
        <img :data-src="xinziImg" />
      </div>
    </div>

    <div class="index_eighth">
      <h1 class="h1_title">多种就业渠道 帮助学员优质就业</h1>
      <div class="line">
        <div class="line_dot"></div>
      </div>
      <img :data-src="index_eighth_img" />
    </div>
    <div class="index_btn" @click="advice()">咨询一下</div>
    <div class="shitingke">
      <h1 class="h1_title">7天试听课程领取</h1>
      <h2 class="h2_des">试听很重要，通过试听感受真实的学习环境和氛围</h2>
      <div class="index_third_input">
        <img :data-src="mobileImg" />
        <input class="form_input" type="text" v-model="inputMobileVal" placeholder="您的手机号  领取试听名额" />
      </div>
      <div class="index_third_btn" @click="handleMobileClick">查询附近试学校区</div>
    </div>
    <div class="index_eighth">
      <h1 class="h1_title">校园环境展示</h1>
      <h2 class="h2_des">优美的学习环境，更有助于快乐学习</h2>
      <div class="line">
        <div class="line_dot"></div>
      </div>
      <img :data-src="newImg" />
    </div>
    <div class="index_btn" @click="advice()">预约到校参观</div>

    <div class="index_tenth">
      <h1 class="h1_title">常见问题解答</h1>
      <div class="line">
        <div class="line_dot"></div>
      </div>
      <div class="index_tenth_list">
        <div class="index_tenth_list_item">
          <div class="list_item_num">1</div>
          <div class="list_item_text">对年龄和学历有要求吗？</div>
          <div class="list_item_btn" @click="advice()">查看答案</div>
        </div>
        <div class="index_tenth_list_item">
          <div class="list_item_num">2</div>
          <div class="list_item_text">0基础能学会吗？</div>
          <div class="list_item_btn" @click="advice()">查看答案</div>
        </div>
        <div class="index_tenth_list_item">
          <div class="list_item_num">3</div>
          <div class="list_item_text">学费多少钱？能分期吗？</div>
          <div class="list_item_btn" @click="advice()">查看答案</div>
        </div>
        <div class="index_tenth_list_item">
          <div class="list_item_num">4</div>
          <div class="list_item_text">学多长时间？怎么上课？</div>
          <div class="list_item_btn" @click="advice()">查看答案</div>
        </div>
        <div class="index_tenth_list_item">
          <div class="list_item_num">5</div>
          <div class="list_item_text">好学吗？学不会怎么办？</div>
          <div class="list_item_btn" @click="advice()">查看答案</div>
        </div>
        <div class="index_tenth_list_item">
          <div class="list_item_num">6</div>
          <div class="list_item_text">学校在哪里？吃饭，住宿怎么安排？</div>
          <div class="list_item_btn" @click="advice()">查看答案</div>
        </div>
        <div class="index_tenth_list_item">
          <div class="list_item_num">7</div>
          <div class="list_item_text">初/高中学历，学完能拿到大专学历？</div>
          <div class="list_item_btn" @click="advice()">查看答案</div>
        </div>
      </div>
    </div>
    <div>
      <footers style="margin-top: 50px;"></footers>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import "swiper/js/swiper.min.js";
const axios = require("axios").default;
import footers from "../../../components/footer";
import header_public_yd from "../../../components/header_public_yd/header_public_yd";
export default {
  metaInfo: {
    title: "【积云教育官网】短视频运营|直播带货|Python培训|数字技术培训机构",
    meta: [{
      name: "description",
      content: "积云教育,IT职业教育领导品牌.以促进稳定高薪就业为导向,开设AI短视频大师班,人工智能Python,AI数字媒体,国际数字人才专修班等热门IT课程.积云教育分校遍布全国,坚持精品课程研发,口碑好,学员及家长认可的数字媒体培训机构.",
    },
    {
      name: "keywords",
      content: "积云教育,IT培训,短视频运营,Python培训,直播带货,数字技术培训机构",
    },
    ],
  },
  mounted () {
    new Swiper(".index_six_list", {
      autoplay: true, //可选选项，自动滑动
      slidesPerView: "3",
      loop: true,
    });

    new Swiper(".index_first", {
      autoplay: true, //可选选项，自动滑动
      loop: true,
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    setTimeout(() => {
      this.initLazyLoading()
    }, 0);
  },
  created () {
    this.getofferList();

    var domain = window.location.host;
    var urlarr = domain.split(".");
    if (urlarr[0] == "www") {
      this.imgshow = "false";
    } else {
      this.imgshow = "true";
    }
  },
  components: {
    footers,
    header_public_yd
  },
  data () {
    return {
      bannerOne: 'https://jyoa-1300845702.cos.ap-beijing.myqcloud.com/uploads/20250117/5d9bd0262004bb9de42f443356324032.jpg',
      bannerTwo: 'https://jyoa-1300845702.cos.ap-beijing.myqcloud.com/uploads/20250117/681fc47c31b475e2a60cb22c49006fc9.jpg',
      bannerThree: 'https://jyoa-1300845702.cos.ap-beijing.myqcloud.com/uploads/20250117/b831d5250ea8028902ed6faae4e9d62d.jpg',
      indexCourse2: require('../../../assets/image/index/index_course02.jpg'),
      indexCourse4: require('../../../assets/image/index/index_course04.jpg'),
      AILogo: require('../../../assets/image/index/AILogo.jpg'),
      numberLogo: require('../../../assets/image/index/numberLogo.jpg'),
      indexDot: require('../../../assets/image/index/index_dot.png'),
      mobileImg: require('../../../assets/image/index/mobile.png'),
      indexFourthTop: require('../../../assets/image/index/index_fourth_top.jpg'),
      indexFourth1: require('../../../assets/image/index/index_fourth01.png'),
      indexFourth2: require('../../../assets/image/index/index_fourth02.png'),
      indexFourth3: require('../../../assets/image/index/index_fourth03.png'),
      indexFourth4: require('../../../assets/image/index/index_fourth04.png'),
      indexFourth5: require('../../../assets/image/index/index_fourth05.png'),
      indexFourth6: require('../../../assets/image/index/index_fourth06.png'),
      indexFourth7: require('../../../assets/image/index/index_fourth07.png'),
      indexFourth8: require('../../../assets/image/index/index_fourth08.png'),
      indexFourth9: require('../../../assets/image/index/index_fourth09.png'),
      index_five_bg: require('../../../assets/image/index/index_five_bg.png'),
      xinziImg: require('../../../assets/image/index/xinzi.png'),
      index_eighth_img: require('../../../assets/image/index/index_eighth_img.jpg'),
      newImg: require('../../../assets/image/index/new.png'),
      imgshow: "false",
      offerlist: [],
      inputVal: "",
      inputMobileVal: "",
      eduIndex: 0, //默认选中第一个
      eduText: "初中",
      eduList: ["初中", "高中", "中专", "大专", "本科及以上"],
      statusIndex: 0, //默认选中第一个
      statusText: "在职",
      statusList: ["在职", "待业", "应届毕业生", "其他"],
      ageIndex: 0, //默认选中第一个
      ageText: "15.5-20周岁",
      ageList: ["15.5-20周岁", "21-30周岁", "31-40周岁", "其他"],
      isCollapse:false,
    };
  },

  methods: {
    initLazyLoading() {
      this.initImgLoading()
      this.initBgLoading()
    },
    initImgLoading() {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadImage(entry.target, observer);
          }
        });
      }, {
        rootMargin: '50px',
        threshold: 0.1
      });
      document.querySelectorAll('img').forEach(img => {
        observer.observe(img);
      });
    },
    loadImage(img, observer) {
      const src = img.getAttribute('data-src');
      if (src) {
        img.src = src;
        // alert(1)
        img.onload = () => {
          observer.unobserve(img);
        };
      }
    },
    initBgLoading() {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.loadBg(entry.target, observer);
          }
        });
      }, {
        rootMargin: '50px',
        threshold: 0.1
      });
      document.querySelectorAll('.lazy-bg').forEach(img => {
        observer.observe(img);
      });
    },
    loadBg(img, observer) {
      let src;
      if (this.isPC()) {
        src = img.getAttribute('data-bg');
      } else {
        src = img.getAttribute('data-bg-m');
      }
      if (src) {
        img.style.setProperty('background-image', `url(${window.location.origin + src})`, 'important');
        observer.unobserve(img);
      }
    },
    advice () {
      window.mantis.requestChat()
    },
    addClose(value) {
      this.isCollapse = value
    },
    handleClickEducation (item, index) {
      this.eduIndex = index;
      this.eduText = item;
    },
    handleClickStatus(item,index){
      this.statusIndex = index;
      this.statusText = item;
    },
    handleClickAge (item, index) {
      this.ageIndex = index;
      this.ageText = item;
    },
    handleClickFree () {
      if (localStorage.clickNum == 1) {
        alert("您已提交，请不要重复提交");
        return false;
      }
      var url = encodeURIComponent(window.location.href);
      var param = {
        age: this.ageText,
        // education: this.eduText,
        work_status: this.statusText,
        source_url: url,
        telephone: this.inputVal
      };
      axios
        .post("/api/audition/apply", param)
        .then(res => {
          if (res.data.code == 1) {
            localStorage.clickNum = '1'
            window.sendPage(this.inputVal, this.eduText)
          } else {
            alert(res.data.msg);
          }
        })
        .catch(errors => {
          alert(errors.data.msg);
        });
    },
    handleMobileClick () {
      if (localStorage.clickNum == 1) {
        alert('您已提交，请不要重复提交');
        return false;
      }
      var url = encodeURIComponent(window.location.href);
      var param = {
        age: "",
        education: "试听课",
        source_url: url,
        telephone: this.inputMobileVal,
      };

      axios
        .post("/api/audition/apply", param)
        .then(res => {
          if (res.data.code == 1) {
            localStorage.clickNum = '1'
            window.sendPage(this.inputMobileVal, "试听课")
          } else {
            alert(res.data.msg);
          }
        })
        .catch(errors => {
          alert(errors.data.msg);
        });
    },
    toqianduan () {
      this.$router.push("/cloud_computing");
    },
    toshipin () {
      // this.$router.push("/newvisual_design");
    },
    todashuju () {
      this.$router.push("/new_bigdata");
    },
    torengongzhineng () {
      this.$router.push("/artificial_intelligence");
    },
    toquanzhan () {
      this.$router.push("/new_fullstack");
    },
    toceshi () {
      if (window.localStorage.getItem('username') == '1') {
        this.$router.push({ name: 'test', params: { name: 'param' } })
      } else {
        this.$router.push("/software_testing");
      }
    },
    getofferList () {
      var limit = 50;
      axios.get("/api/job/getJobList?limit=" + limit).then((res) => {
        this.offerlist = res.data.data; //就业喜报列表
        this.$nextTick(() => {
          new Swiper(".index_seventh_swiper_list", {
            notNextTick: true,
            direction: "vertical",
            setWrapperSize: true,
            autoHeight: true,
            freeMode: true, //true则是自由模式，不会自动贴合滑动位置
            autoplay: {
              autoplayDisableOnInteraction: false,
              disableOnInteraction: false,
              delay: 800,
            },
            loop: true, //循环
            observer: true, //修改swiper自己或子元素时，自动初始化swiper
            observeParents: true, //修改swiper的父元素时，自动初始化swiper
            slidesPerView: "auto", //slide可见数量
          });
        });
      });
    },
  },
};
</script>
<style scoped>
@import url("../../../assets/css/index/index.css");
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}
</style>
