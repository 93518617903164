import Vue from "vue";
import VueRouter from "vue-router";

// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

let routes = [
  // 重定向
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "index" */ "./views/index/index.vue"),
  },
  {
    path: "/index",
    component: () =>
      import(/* webpackChunkName: "index" */ "./views/index/index.vue"),
  },
  {
    path: "/calculationPage",
    component: () =>
      import(
        /* webpackChunkName: "calculationPage" */ "./views/calculationPage/calculationPage.vue"
      ),
  },
  // {
  //   path: "/visual_design",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "visual_design" */ "./views/visual_design/visual_design.vue"
  //     ),
  // },
  {
    path: "/brochure",
    component: () =>
      import(
        /* webpackChunkName: "brochure" */ "./views/brochure/brochure.vue"
      ),
  },
  {
    path: "/internet_things",
    component: () =>
      import(
        /* webpackChunkName: "internet_things" */ "./views/internet_things/internet_things.vue"
      ),
  },
  {
    path: "/newMedia",
    component: () =>
      import(
        /* webpackChunkName: "newMedia" */ "./views/newMedia/newMedia.vue"
      ),
  },
  {
    path: "/media",
    component: () =>
      import(
        /* webpackChunkName: "newMedia" */ "./views/media/media.vue"
      ),
  },
  {
    path: "/intelligence",
    component: () =>
      import(
        /* webpackChunkName: "intelligence" */ "./views/intelligence/intelligence.vue"
      ),
  },
  {
    path: "/short_video",
    component: () =>
      import(
        /* webpackChunkName: "intelligence" */ "./views/short_video/short_video.vue"
      ),
  },
  {
    path: "/big_data",
    component: () =>
      import(
        /* webpackChunkName: "big_data" */ "./views/big_data/big_data.vue"
      ),
  },
  {
    path: "/new_bigdata",
    component: () =>
      import(
        /* webpackChunkName: "/new_bigdata" */ "./views/new_bigdata/new_bigdata.vue"
      ),
  },
  {
    path: "/jiyun_solar",
    component: () =>
      import(
        /* webpackChunkName: "jiyun_solar" */ "./views/jiyun_solar/jiyun_solar.vue"
      ),
  },
  {
    path: "/jiyun_jiaoyu",
    component: () =>
      import(
        /* webpackChunkName: "jiyun_jiaoyu" */ "./views/jiyun_jiaoyu/jiyun_jiaoyu.vue"
      ),
  },
  {
    path: "/jiyun_search",
    component: () =>
      import(
        /* webpackChunkName: "jiyun_search" */ "./views/jiyun_search/jiyun_search.vue"
      ),
  },
  {
    path: "/jiyun_major",
    component: () =>
      import(
        /* webpackChunkName: "jiyun_major" */ "./views/jiyun_major/jiyun_major.vue"
      ),
  },
  {
    path: "/employment",
    component: () =>
      import(
        /* webpackChunkName: "employment" */ "./views/employment/employment.vue"
      ),
  },
  {
    path: "/jiyun_province",
    component: () =>
      import(
        /* webpackChunkName: "jiyun_province" */ "./views/jiyun_province/jiyun_province.vue"
      ),
  },
  {
    path: "/jiyun_china",
    component: () =>
      import(
        /* webpackChunkName: "jiyun_china" */ "./views/jiyun_china/jiyun_china.vue"
      ),
  },
  {
    path: "/news_detail/:id",
    component: () =>
      import(
        /* webpackChunkName: "news_detail" */ "./views/news_detail/news_detail.vue"
      ),
  },
  {
    path: "/aboutus",
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "./views/aboutus/aboutus.vue"),
  },
  {
    path: "/specialtopics",
    component: () =>
      import(
        /* webpackChunkName: "specialtopics" */ "./views/specialtopics/specialtopics.vue"
      ),
  },
  {
    path: "/fullstack",
    component: () =>
      import(
        /* webpackChunkName: "fullstack" */ "./views/fullstack/fullstack.vue"
      ),
  },
  {
    path: "/cloud_computing",
    component: () =>
      import(
        /* webpackChunkName: "cloud_computing" */ "./views/cloud_computing/cloud_computing.vue"
      ),
  },
  {
    path: "/news_list",
    component: () =>
      import(
        /* webpackChunkName: "news_list" */ "./views/news_list/news_list.vue"
      ),
  },
  {
    path: "/product_introduction",
    component: () =>
      import(
        /* webpackChunkName: "product_introduction" */ "./views/product_introduction/product_introduction.vue"
      ),
  },
  {
    path: "/test-major",
    component: () =>
      import(
        /* webpackChunkName: "test-major" */ "./views/test-major/test-major.vue"
      ),
  }
];

let router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
export default router;
