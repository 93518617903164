var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_bottom"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"index_bottom_copytext"},[_c('p',[_vm._v("Copyright 2018 - "+_vm._s(_vm.year)+" Usian.cn 北京优思安科技有限公司")]),_c('p',[_vm._v("版权所有 京ICP备14057463号-1")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_bottom_item"},[_c('p',{staticClass:"bottom_title"},[_vm._v("北京校区地址：")]),_c('p',{staticClass:"bottom_text"},[_vm._v("北京市海淀区上庄路白水洼 77 号")]),_c('div',{staticClass:"index_bottom_line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_bottom_item"},[_c('p',{staticClass:"bottom_title"},[_vm._v("河南校区地址：")]),_c('p',{staticClass:"bottom_text"},[_vm._v("郑州市航空港区航田智能手机产业园")]),_c('div',{staticClass:"index_bottom_line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_bottom_item"},[_c('div',{staticClass:"dyh_box"},[_c('img',{staticClass:"dyh",attrs:{"src":require("../assets/image/index/dyh.jpg")}}),_c('p',{staticClass:"dyhtext"},[_vm._v("关注订阅号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_bottom_item"},[_c('p',{staticClass:"bottom_text"},[_vm._v("联系我们：400-800-2320")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_bottom_item"},[_c('p',{staticClass:"bottom_text"},[_vm._v("反馈与建议：yunyingzu@usian.cn")]),_c('div',{staticClass:"index_bottom_line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_bottom_item",staticStyle:{"padding-bottom":"3vw"}},[_c('p',{staticClass:"bottom_title"},[_vm._v("关于我们")]),_c('p',{staticClass:"bottom_text"},[_vm._v("积云教育是一家提供高端数字人才培训和高薪就业整体解决方案的教育集团，始终秉持“人人皆可成才”的育人理念、以“创新教育、成就未来”为使命、以“高就业率、高就业薪资、高对口就业率”为培养目标、坚持“产教融合、校企合一”。集团总部位于北京，现拥有北京、河南等多个线下大校区，累计服务全国35000多名学员")])])
}]

export { render, staticRenderFns }