<template>
    <div class="page" :style="{ height:isCollapse ? '100vh' : 'auto'}">
        <header_public :navindex='3' :color="headerColor" v-if="!ismobile"></header_public>
        <header_public_yd v-if="ismobile" :navindex='3' @addClose="addClose" class="fixed-header"></header_public_yd>
        <div class="mybanner swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img class="swiper-banner-img" style="width: 100%;" :data-src="bannerBgPC"
                        v-if="!ismobile" loading="lazy" />
                    <img class="swiper-banner-img" style="width: 100vw;"  :data-src="bannerBgYD"
                        v-else loading="lazy" />
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div>
            <!-- 抓住时代红利 你准备好了么 -->
            <div class="big-title">抓住时代红利 你准备好了么</div">
                <div class="fit-box-item" style="position: relative;">
                    <div class="holi-title">随着 5G 时代的开启，互联网数字媒体呈现出百花齐放的繁荣景象，数字媒体与AI智能工具结合成为当下新崛起的热门赛道</div>
                    <img class="title-line" :data-src="titleLineBg2" style="margin-bottom: 51px;">
                    <div class="holi-box-item-div">
                        <div v-for="(item,index) in fitList" :key="item.title">
                            <div>
                                <img :data-src="ismobile ? item.imgUrlYd : item.imgUrl" alt="">
                                <div style="position: relative;">
                                    <div class="holi-box-title"
                                        :style="{ background: ismobile? item.ydBgColor : item.bgcolor }">
                                        {{item.title}}</div>
                                    <div class="holi-box-content">{{item.content}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg" v-if="!ismobile"></div>
                </div>
            </div>
            <!-- 6大课程优势  提升你的就业竞争力 -->
            <div class="kecheng-box">
                <div class="kecheng-content">
                    <div class="big-title kecheng-titles" v-if="!ismobile">6大课程优势 提升你的就业竞争力</div>
                    <div class="big-title kecheng-titles" v-else>6大课程优势<br />提升你的就业竞争力</div>
                    <img class="title-line title-line-ys" :data-src="titleLineBg2">
                    <div class="kecheng-list">
                        <div v-for="(item,index) in classList" :key="item.title">
                            <div class="kc-item" :style="{
                                backgroundImage: `url(${item.imgUrl})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover'
                              }" v-if="!ismobile">
                                <div class="kc-box-title">
                                    {{item.title}}
                                </div>
                                <div class="kc-box-content">
                                    {{item.content}}
                                </div>
                            </div>

                            <div class="kc-item-yd" v-if="ismobile">
                                <div class="kc-box-title-yd" :style="{
                                    backgroundImage: `url(${item.topUrl})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundSize: 'cover'
                                  }">
                                    {{item.title}}
                                </div>
                                <div class="kc-box-content-yd">
                                    {{item.content}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 从基础到实战 全面掌握AI数字媒体技能 -->
            <div class="jineng-box" v-if="!ismobile">
                <div class="jineng-content">
                    <div class="big-title big-titles kecheng-titles">从基础到实战 全面掌握AI数字媒体技能</div>
                    <img class="title-line title-line-ys" :data-src="titleLineBg" v-if="!ismobile">
                    <div class="jn-item-box">
                        <div v-for="(item,index) in jnList" :key="item.title" :class="{'jn-item':!item.showImg}">
                            <div v-if="!item.showImg">
                                <div>
                                    <img :data-src="item.imgUrl" alt="" class="jn-img">
                                    <div class="jn-title">{{item.title}}</div>
                                </div>
                                <div class="list">
                                    <div v-for="(items, index) in item.content" :key="items">
                                        <img :data-src="item.logo" alt="" class="duigou">
                                        <div class="list-item">{{ items }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.showImg">
                                <img :data-src="item.bgUrl" alt="" style="width: 373px;height: 364px">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="jineng-box" v-else>
                <div class="jineng-content">
                    <div class="big-title big-titles kecheng-titles">从基础到实战 <br> 全面掌握AI数字媒体技能</div>
                    <img class="title-line title-line-ys" :data-src="titleLineBg">
                    <div class="jn-item-box">
                        <div v-for="(item, index) in jnYdList">
                            <div class="six-box">
                                <div class="six-left-box">
                                    <img :data-src="item.bgUrl" class="left-img">
                                </div>
                                <div class="right-logo">
                                    <img :data-src="item.indexBg">
                                    <span class="right-logo-num">{{'0' + (index + 1)}}</span>
                                </div>
                                <div style="margin-top: 2.5vw;padding:0vw 0 4.5vw 4.4vw;height: 83%;">
                                    <div class="six-right-box-title">{{item.title}}</div>
                                    <div class="item-content-box">
                                        <div v-for="(items, index) in item.content" :key="items">
                                            <div class="flex-start">
                                                <img :data-src="item.logo" alt="" class="duigou">
                                                <div class="items-class">{{ items }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--热门AI技能全覆盖  简单好学变现快  -->
            <div class="ai-bianxian-box">
                <div class="bianxian-content">
                    <div class="big-title" style="padding-top:69px;margin-top: 0;" v-if="!ismobile">热门AI技能全覆盖 简单好学变现快
                        </div">
                        <div class="big-title" style="padding-top:9.6vw;margin-top: 0;" v-else>热门AI技能全覆盖 <br> 简单好学变现快
                            </div">
                            <div>
                                <img class="title-line" :data-src="titleLineBg2"
                                    style="margin-bottom: 78px;">
                                <div class="bianxian-box-item-div">
                                    <div v-for="(item,index) in bianxianList" :key="item.title">
                                        <div>
                                            <img :data-src="item.imgUrl" alt="">
                                            <div style="position: relative;">
                                                <div class="bianxian-box-title" :style="{ background: item.bgcolor }">
                                                    {{item.title}}</div>
                                                <div class="bianxian-item-content">{{item.content}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="remen-box" v-if="!ismobile">
                        <div class="big-title">热门问题</div">
                            <img class="title-line" :data-src="titleLineBg2">
                            <div class="remen-one-box">
                                <div v-for="(item, index) in aiSceneList" :key="item.title" class="remen-item">
                                    <div class="flex-around" style="align-items: center;">
                                        <div>
                                            <div class="ai-question-box">
                                                <div class="ai-question-box-img">
                                                    <img :data-src="item.imgUrl" alt="" class="ai-question-box-tu">
                                                </div>
                                                <div class="ai-question-box-title">
                                                    {{ item.title }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="remen-box" v-else>
                            <div class="big-title">热门问题</div">
                                <img class="title-line" :data-src="titleLineBg2">
                                <div class="remen-content">
                                    <div v-for="(item, index) in orderedAiSceneList" :key="item.title">
                                        <div>
                                            <div>
                                                <div class="ai-question-box">
                                                    <div class="ai-question-box-img">
                                                        <img :data-src="item.imgUrl" alt="" class="ai-question-box-tu">
                                                    </div>
                                                    <div class="ai-question-box-title">
                                                        {{ item.title }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <bottom_public_new v-if="!ismobile"></bottom_public_new>
                            <div style="background-color: #FAE9E7;">
                                <bottom_public_new_yd v-if="ismobile" :bgcolor="bgcolor">
                                </bottom_public_new_yd>
                            </div>
                        </div>
                    </div>
</template>

<script>
    import Swiper from "swiper";
    import "swiper/css/swiper.min.css";
    import "swiper/js/swiper.min.js";
    import bottom_public_new from "../../components/bottom_public_new/bottom_public_new";
    import bottom_public_new_yd from "../../components/bottom_public_new_yd/bottom_public_new_yd";
    import header_public from "../../components/header_public/header_public";
    import header_public_yd from "../../components/header_public_yd/header_public_yd";
    export default {
        metaInfo: {
            title: "【积云教育官网】短视频运营|直播带货|Python培训|数字技术培训机构",
            meta: [{
                name: "description",
                content: "积云教育,IT职业教育领导品牌.以促进稳定高薪就业为导向,开设AI短视频大师班,人工智能Python,AI数字媒体,国际数字人才专修班等热门IT课程.积云教育分校遍布全国,坚持精品课程研发,口碑好,学员及家长认可的数字媒体培训机构.",
            },
            {
                name: "keywords",
                content: "积云教育,IT培训,短视频运营,Python培训,直播带货,数字技术培训机构",
            },
            ],
        },
        components: {
            bottom_public_new,
            bottom_public_new_yd,
            header_public,
            header_public_yd
        },
        computed: {
            ismobile() {
                return window.innerWidth <= 768 ? true : false; // 你可以根据需要调整这个阈值
            },
            jnYdList() {
                return this.jnList.filter((item) => {
                    return !item.showImg;
                });
            },
            orderedAiSceneList() {
                const order = this.mobileOrder;
                if (window.innerWidth <= 768) {
                    return order.map(index => this.aiSceneList[index]);
                }
            }
        },
        data() {
            return {
                isCollapse: false,
                innerWidth: 0,
                ismobile: false,
                headerColor: '#010C27',
                bg1: require('../../assets/videoImg/ysBanner.png'),
                bgcolor: 'linear-gradient(to right, #FE502D, #FFA339)',
                bannerBgPC: 'https://jyoa-1300845702.cos.ap-beijing.myqcloud.com/uploads/20250118/28198ad3c0f37f64701dc6c0cb6653cc.png',
                bannerBgYD: 'https://jyoa-1300845702.cos.ap-beijing.myqcloud.com/uploads/20250118/108d02dcf58b77b8d121822da9f0c314.png',
                titleLineBg:require('../../assets/mediaImg/title-line.png'),
                titleLineBg2:require('../../assets/mediaImg/title-line2.png'),
                fitList: [//抓住时代红利
                    {
                        imgUrl: require('../../assets/mediaImg/holi-one.png'),
                        imgUrlYd: require('../../assets/mediaImg/holiyd1.png'),
                        title: '时代风口',
                        content: '各行各业必用职业，流量巨大，发展空间大',
                        bgcolor: 'linear-gradient(to bottom, #49E9FF,#57B8FF)',
                        ydBgColor: '#fff'
                    },
                    {
                        imgUrl: require('../../assets/mediaImg/holi-two.png'),
                        imgUrlYd: require('../../assets/mediaImg/holiyd2.png'),
                        title: '弯道超车',
                        ydBgColor: '#fff',
                        bgcolor: 'linear-gradient(to bottom, #FF8F4B,#EB5B3C)',
                        content: '掌握AI技术，小白也能逆袭成为专业大师',
                    },
                    {
                        imgUrlYd: require('../../assets/mediaImg/holiyd3.png'),
                        imgUrl: require('../../assets/mediaImg/holi-three.png'),
                        bgcolor: 'linear-gradient(to bottom, #6BDF95,#23C084)',
                        title: '轻松赚钱',
                        ydBgColor: '#fff',
                        content: 'AI开源资源丰富，利用业余时间轻松快速赚钱',
                    },
                    {
                        imgUrl: require('../../assets/mediaImg/holi-four.png'),
                        imgUrlYd: require('../../assets/mediaImg/holiyd4.png'),
                        title: '打造个人IP',
                        ydBgColor: '#fff',
                        content: '人人自媒体时代，借助AI工具独立创作',
                        bgcolor: 'linear-gradient(to bottom, #8589FF,#5B51F3)',
                    },
                ],
                classList: [
                    {
                        title: '锁定AI数字媒体赛道',
                        imgUrl: require('../../assets/mediaImg/yi-bg.png'),
                        topUrl: require('../../assets/mediaImg/topBg1.png'),
                        content: '精准定位AI数字媒体技术，AIGC数据模型与新媒体行业深度融合，拥抱时代焦点',
                    },
                    {
                        title: '培养稀缺人才',
                        imgUrl: require('../../assets/mediaImg/er-bg.png'),
                        topUrl: require('../../assets/mediaImg/topBg2.png'),
                        content: '底层创作逻辑+商业化项目，培养精通“编/导/拍/剪/包装/制作”全流程的稀缺数字媒体影视人才',
                    },
                    {
                        title: '课程技术全面',
                        imgUrl: require('../../assets/mediaImg/san-bg.png'),
                        topUrl: require('../../assets/mediaImg/topBg3.png'),
                        content: '课程内容饱满技术全面，从导演思维到影视摄影、从动画制作/高级建模到资深制作等等',
                    },
                    {
                        title: '实训实战教学',
                        imgUrl: require('../../assets/mediaImg/si-bg.png'),
                        topUrl: require('../../assets/mediaImg/topBg4.png'),
                        content: '设备齐全，配置专业画室/影棚/摄影设备等，通过实训实战教学真正掌握影视DIT技术',
                    },
                    {
                        title: '大咖阵容授课',
                        imgUrl: require('../../assets/mediaImg/wu-bg.png'),
                        topUrl: require('../../assets/mediaImg/topBg5.png'),
                        content: '新媒体大咖讲师，手把手带领学员从商业需求拆解到项目落地执行',
                    },
                    {
                        title: '中台精准护航',
                        imgUrl: require('../../assets/mediaImg/six-bg.png'),
                        topUrl: require('../../assets/mediaImg/topBg6.png'),
                        content: '依托全新升级的教学中台"教/学/练/测/评"全程护航，精准定位+及时反馈+持续改进，推动学员成才',
                    },
                ],
                jnList: [
                    {
                        title: '短视频拍摄剪辑能力培养',
                        imgUrl: require('../../assets/mediaImg/title-one.png'),
                        bgUrl: require('../../assets/mediaImg/jineng1.png'),
                        logo: require('../../assets/mediaImg/duigou.png'),
                        content: ['平面拍摄+视频拍摄', 'AIGC作图', '短视频剪辑技巧'],
                        showImg: false,
                        indexBg: require('../../assets/mediaImg/jineng-logo1.png'),
                    },
                    {
                        title: '短剧特效制作能力培养',
                        imgUrl: require('../../assets/mediaImg/title-two.png'),
                        bgUrl: require('../../assets/mediaImg/jineng2.png'),
                        logo: require('../../assets/mediaImg/duigou.png'),
                        indexBg: require('../../assets/mediaImg/jineng-logo2.png'),
                        showImg: false,
                        content: ['视频后期包装，字幕特效制作', '剧情类短视频、单元短剧剪辑技巧', '电商种草信息流类短视频创作'],
                    },
                    {
                        title: '短剧全流程制作能力培养',
                        showImg: false,
                        imgUrl: require('../../assets/mediaImg/title-three.png'),
                        bgUrl: require('../../assets/mediaImg/jineng3.png'),
                        logo: require('../../assets/mediaImg/duigou.png'),
                        indexBg: require('../../assets/mediaImg/jineng-logo3.png'),
                        content: ['编导（文案脚本策划）+个人账号运营', 'AIGC智能脚本+短视频创作', '短剧实战项目全流程'],
                    },
                    {
                        title: '爆款视频流量运营能力培养',
                        showImg: false,
                        imgUrl: require('../../assets/mediaImg/title-four.png'),
                        indexBg: require('../../assets/mediaImg/jineng-logo4.png'),
                        bgUrl: require('../../assets/mediaImg/jineng4.png'),
                        logo: require('../../assets/mediaImg/duigou.png'),
                        content: ['AI数字人打造+AI信息流视频剪辑', '短视频平台流量运营知识+投放技巧', 'AI数字人打造+直播带货全流程'],
                    },
                    {
                        bgUrl: require('../../assets/mediaImg/center-img.png'),
                        showImg: true,
                    },
                    {
                        showImg: false,
                        title: 'AI直播间搭建及运营能力培养',
                        bgUrl: require('../../assets/mediaImg/jineng5.png'),
                        indexBg: require('../../assets/mediaImg/jineng-logo5.png'),
                        imgUrl: require('../../assets/mediaImg/title-five.png'),
                        logo: require('../../assets/mediaImg/duigou.png'),
                        content: ['短视频运营实战+个人账号', '直播团队运营+直播投流计划', '直播间搭建+直播账号运营'],
                    },
                ],
                bianxianList: [
                    {
                        imgUrl: require('../../assets/mediaImg/bianxian1.png'),
                        // imgUrlYd: require('../../assets/mediaImg/contentImgOneYd.png'),
                        title: 'AI图文带货',
                        content: '拓展副业带货赚钱渠道',
                        bgcolor: 'linear-gradient(68deg, #EB5B3C,#FF8F4B)',
                    },
                    {
                        imgUrl: require('../../assets/mediaImg/bianxian2.png'),
                        // imgUrlYd: require('../../assets/mediaImg/contentImgOneYd.png'),
                        title: 'AI小说漫画',
                        content: '实现小说短剧变现',
                        bgcolor: 'linear-gradient(68deg, #EB5B3C,#FF8F4B)',
                    },
                    {
                        imgUrl: require('../../assets/mediaImg/bianxian3.png'),
                        // imgUrlYd: require('../../assets/mediaImg/contentImgOneYd.png'),
                        title: 'AI商业插画',
                        content: '实现壁纸图片变现',
                        bgcolor: 'linear-gradient(68deg, #EB5B3C,#FF8F4B)',
                    },
                    {
                        imgUrl: require('../../assets/mediaImg/bianxian4.png'),
                        // imgUrlYd: require('../../assets/mediaImg/contentImgOneYd.png'),
                        title: 'AI人像摄影',
                        content: '快速专业摄影变现',
                        bgcolor: 'linear-gradient(68deg, #EB5B3C,#FF8F4B)',
                    },
                    {
                        imgUrl: require('../../assets/mediaImg/bianxian5.png'),
                        // imgUrlYd: require('../../assets/mediaImg/contentImgOneYd.png'),
                        title: 'AI创意广告',
                        content: '实现创意广告变现',
                        bgcolor: 'linear-gradient(68deg, #EB5B3C,#FF8F4B)',
                    },
                    {
                        imgUrl: require('../../assets/mediaImg/bianxian6.png'),
                        // imgUrlYd: require('../../assets/mediaImg/contentImgOneYd.png'),
                        title: 'AI卡通形象',
                        content: '实现IP形象变现',
                        bgcolor: 'linear-gradient(68deg, #EB5B3C,#FF8F4B)',
                    },
                    {
                        imgUrl: require('../../assets/mediaImg/bianxian7.png'),
                        // imgUrlYd: require('../../assets/mediaImg/contentImgOneYd.png'),
                        title: 'AI游戏原画',
                        content: '实现游戏副业变现',
                        bgcolor: 'linear-gradient(68deg, #EB5B3C,#FF8F4B)',
                    },
                    {
                        imgUrl: require('../../assets/mediaImg/bianxian8.png'),
                        // imgUrlYd: require('../../assets/mediaImg/contentImgOneYd.png'),
                        title: 'AI电商合成',
                        content: '实现爆款产品变现',
                        bgcolor: 'linear-gradient(68deg, #EB5B3C,#FF8F4B)',
                    },
                ],
                aiSceneList: [
                    {
                        title: '有实战项目吗？',
                        imgUrl: require("../../assets/mediaImg/question1.png")
                    },
                    {
                        title: '在校能直播吗？',
                        imgUrl: require("../../assets/mediaImg/question5.png")
                    },
                    {
                        title: '可以试听吗？',
                        imgUrl: require("../../assets/mediaImg/question2.png")
                    },
                    {
                        title: '学费多少钱？',
                        imgUrl: require("../../assets/mediaImg/question6.png")
                    },
                    {
                        title: '学习周期是多久？',
                        imgUrl: require("../../assets/mediaImg/question3.png")
                    },
                    {
                        title: '学多久能接单？',
                        imgUrl: require("../../assets/mediaImg/question7.png")
                    },
                    {
                        title: '有免费的AI工具吗？',
                        imgUrl: require("../../assets/mediaImg/question4.png")
                    },
                    {
                        title: '0基础可以学吗？',
                        imgUrl: require("../../assets/mediaImg/question8.png")
                    },
                ],
                mobileOrder: [
                    0, 2, 4, 6, 1, 3, 5, 7
                ]
            }
        },
        created() {
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        },
        mounted() {
            this.innerWidth = window.innerWidth
            this.ismobile = this.innerWidth <= 768 ? true : false
            window.addEventListener("resize", this.handleResize);
            setTimeout(() => {
                this.initLazyLoading()
            }, 0);
        },
        methods: {
            initLazyLoading() {
                this.initImgLoading()
                this.initBgLoading()
            },
            initImgLoading(){
                const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            this.loadImage(entry.target, observer);
                        }
                    });
                }, {
                    rootMargin: '50px',
                    threshold: 0.1
                });
                document.querySelectorAll('img').forEach(img => {
                    observer.observe(img);
                });
            },
            loadImage(img, observer) {
                const src = img.getAttribute('data-src');
                if (src) {
                    img.src = src;
                    // alert(1)
                    img.onload = () => {
                        observer.unobserve(img);
                    };
                }
            },
            initBgLoading(){
                const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            this.loadBg(entry.target, observer);
                        }
                    });
                }, {
                    rootMargin: '50px',
                    threshold: 0.1
                });
                document.querySelectorAll('.lazy-bg').forEach(img => {
                    observer.observe(img);
                });
            },
            loadBg(img, observer) {
                let src;
                if(this.isPC()){
                    src = img.getAttribute('data-bg');
                }else{
                    src = img.getAttribute('data-bg-m');
                }
                if (src) {
                    img.style.setProperty('background-image', `url(${window.location.origin + src})`, 'important');
                    observer.unobserve(img);
                }
            },

            addClose(value) {
                this.isCollapse = value
            },
            handleResize() {
                this.innerWidth = window.innerWidth
                this.ismobile = this.innerWidth <= 768 ? true : false
            },
            kefu() {
                window.mantis.requestChat()
            },

        }
    }
</script>

<!-- <style scoped src="../../assets/css/dsp/dsp_pc.css"></style> -->
<style scoped src="./media.css"></style>