<template>
  <div class="index_bottom">
    <!-- <div class="index_bottom_item">
      <p class="bottom_title">积云教育总部</p>
      <p class="bottom_text">北京市昌平区 TBD 云集中心 4 号楼 C 座</p>
      <div class="index_bottom_line"></div>
    </div> -->
    <div class="index_bottom_item">
      <p class="bottom_title">北京校区地址：</p>
      <p class="bottom_text">北京市海淀区上庄路白水洼 77 号</p>
      <div class="index_bottom_line"></div>
    </div>
    <div class="index_bottom_item">
      <p class="bottom_title">河南校区地址：</p>
      <p class="bottom_text">郑州市航空港区航田智能手机产业园</p>
      <div class="index_bottom_line"></div>
    </div>
    <div class="index_bottom_item">
      <!-- <p class="bottom_title">山西校区</p>
      <p class="bottom_text">山西省晋中市榆次区文华街燕金商务楼</p> -->
      <div class="dyh_box">
        <img class="dyh" src="../assets/image/index/dyh.jpg" />
        <p class="dyhtext">关注订阅号</p>
      </div>
      <!-- <div class="index_bottom_line"></div> -->
    </div>
    <div class="index_bottom_item">
      <p class="bottom_text">联系我们：400-800-2320</p>
    </div>
    <div class="index_bottom_item">
      <p class="bottom_text">反馈与建议：yunyingzu@usian.cn</p>
      <div class="index_bottom_line"></div>
    </div>

    <div class="index_bottom_item" style="padding-bottom: 3vw;">
      <p class="bottom_title">关于我们</p>
      <p class="bottom_text">积云教育是一家提供高端数字人才培训和高薪就业整体解决方案的教育集团，始终秉持“人人皆可成才”的育人理念、以“创新教育、成就未来”为使命、以“高就业率、高就业薪资、高对口就业率”为培养目标、坚持“产教融合、校企合一”。集团总部位于北京，现拥有北京、河南等多个线下大校区，累计服务全国35000多名学员</p>
    </div>
   <div class="index_bottom_copytext">
      <p>Copyright 2018 - {{year}} Usian.cn 北京优思安科技有限公司</p>
      <p>版权所有 京ICP备14057463号-1</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
				year: new Date().getFullYear(),
    };
  },
  created() { },
};
</script>

<style>
.index_bottom {
  width: 100vw;
  padding-top: 8vw;
  background-image: url(../assets/image/index/index_bottom_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.codeImg {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 170px;
  right: 66px;
}

.codeBg {
  width: 100%;
  height: auto;
}

.index_bottom_item {
  width: 82.4vw;
  height: auto;
  margin: 0 auto;
  position: relative;
  text-align: left;
}

.index_bottom_line {
  width: 53.067vw;
  height: 1px;
  background-color: #44b3ae;
  margin-top: 1.33vw;
  margin-bottom: 1.6vw;
}

.dyh_box {
  width: 21.067vw;
  height: auto;
  position: absolute;
  top: -10.667vw;
  right: -4vw
}

.dyh_pr {
  position: relative;
}

.dyh {
  width: 21.067vw;
  height: 21.067vw;
}

.dyhtext {
  font-size: 3.2vw;
  text-align: center;
  color: #666666;
}

.bottom_title {
  font-size: 3.467vw;
  margin-bottom: 2.667vw;
  margin-top: 1.6vw;
  color: #43494a;
}

.bottom_text {
  font-size: 2.933vw;
  margin-bottom: 0.267vw;
  line-height: 2;
  color: #666666;
}

.index_bottom_copytext {
  width: 100%;
  height: 15.467vw;
  background-color: #292f48;
  font-size: 3.2vw;
  text-align: center;
  padding-top: 3.733vw;
  box-sizing: border-box;
}

.index_bottom_copytext p {
  width: 100%;
  height: auto;
  color: #ffffff;
}
</style>
