<template>
    <div class="page" :style="{ height:isCollapse ? '100vh' : 'auto'}" style="background-color: #fff;">
        <header_public :navindex='2' :color="headerColor" v-if="!ismobile"></header_public>
        <header_public_yd v-if="ismobile" :navindex='2' @addClose="addClose" class="fixed-header"></header_public_yd>
        <div class="mybanner swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img class="swiper-banner-img" style="width: 100%;" :data-src="bannerBgPC"
                        v-if="!ismobile" loading="lazy" />
                    <img class="swiper-banner-img" :data-src="bannerBgYD" v-else loading="lazy"
                        style="width: 100vw;height:133.6vw;" />
                </div>
            </div>
            <div class="swiper-pagination"></div>
        </div>
        <div>
            <!-- 数智交融多场景应用 人工智能就业“薪”机遇 -->
            <div class="big-title" v-if="!ismobile">数智交融多场景应用 人工智能就业“薪”机遇</div">
                <div class="big-title" v-if="ismobile">数智交融多场景应用<br> 人工智能就业“薪”机遇</div">
                    <img class="title-line" :data-src="titleLineBg2">
                    <div style="padding: 0 4.5vw;">
                        <div class="content-One-box">
                            <div v-for="(item, index) in aiSceneList" :key="item.title" class="arc-item word-class">
                                <div class="flex-around" style="width: 80%;align-items: center;">
                                    <div v-if="index<3">
                                        {{ item.title }}
                                    </div>
                                    <div class="circle"></div>
                                    <div v-if="index>3 || index == 3">
                                        {{ item.title }}
                                    </div>
                                </div>
                            </div>
                            <div class="content-Two-box" v-if="!ismobile">
                                <div v-for="(item, index) in aiSceneOutList" :key="item.title"
                                    class="out-item word-class"
                                    :class="{'left-side': index < 5, 'right-side': index >= 5}">
                                    <div class="flex-around">
                                        <div>
                                            {{ item.title }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-Two-box" v-if="ismobile">
                            <div v-for="(item, index) in aiSceneOutList" :key="item.title" class="yd-content-two-item">
                                <div class="flex-around">
                                    <div :class="{'title-margin' : item.title == '......'}">
                                        {{ item.title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-class">
                    <!-- 哪些人适合学人工智能？ -->
                    <div class="fit-box">
                        <div class="fit-box-item">
                            <div class="big-title big-title-fix" id="shiherenqun">哪些人适合学人工智能？</div>
                            <img class="title-line big-title-line" :data-src="titleLineBg2">
                            <div class="fit-box-item-div">
                                <div v-for="(item,index) in fitList" :key="item.title">
                                    <div>
                                        <img :data-src="ismobile ? item.imgUrlYd : item.imgUrl" alt="">
                                        <div style="position: relative;">
                                            <div class="fit-box-title word-class" :style="{ background: item.bgcolor }">
                                                {{item.title}}</div>
                                            <div class="fit-box-content word-class">{{item.content}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 9大课程优势 提升你的就业竞争力 -->
                    <div>
                        <div class="big-title" id="shiherenqun">9大课程优势 提升你的就业竞争力</div>
                        <img class="title-line yd-line" :data-src="titleLineBg2">
                        <div class="flex qukou-box nine-box" v-if="!ismobile">
                            <div v-for="(item,index) in nineList" :key="item.name" @mousemove="moveNine(index)"
                                @mouseleave="leaveNine">
                                <div class="nine-box-contain">
                                    <div class="nine-top-box"
                                        :style="{ backgroundImage: moveNineIndex == index ? 'url(' + item.imgUrlActive + ')' : 'url(' + item.imgUrl + ')' }"
                                        :class="{ 'active': moveNineIndex == index,'jd-left-item': moveNineIndex != index}">
                                        <div>
                                            <!-- <img :src="moveNineIndex == index ? item.activeLogo : item.logoImg" alt=""> -->
                                            <span class="index-class word-class"
                                                :class="{'index-class-active': moveNineIndex == index}">{{index+1}}</span>
                                        </div>
                                        <span class="nine-title word-class">
                                            {{item.title}}
                                        </span>
                                    </div>
                                    <div class="nine-content word-class">
                                        {{item.content}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="ismobile">
                            <div class="nine-box-yd">
                                <div v-for="(item,index) in nineList" :key="item.name" class="nine-box-item">
                                    <div class="nine-circle">
                                        <img :data-src="item.logoImgYd" alt=""
                                            :style="{height: item.height,width:item.width }"></img>
                                    </div>
                                    <div class="nine-bottom-title word-class">
                                        {{item.titleYd ? item.titleYd : item.title}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 全面解析人工智能核心技能 打造人工智能实战精英 -->
                <div class="analysis-box" v-if="!ismobile">
                    <div>
                        <div class="big-title analysis-title" id="shiherenqun">全面解析人工智能核心技能 打造人工智能实战精英</div>
                        <img class="title-line" :data-src="titleLineBg">
                        <div class="analysis-box-icon flex">
                            <div class="analysis-box-left">
                                <div v-for="(item,index) in analysisList" :key="index" class="flex"
                                    @mousemove="moveAnalysis(index)" @mouseleave="leaveAnalysis"
                                    :class="{ 'analysis-active': analysisIndex == index}">
                                    <div class="sanjiao-title">{{item.leftTitle }}</div>
                                    <img :data-src="jianTouImg" alt="">
                                </div>
                            </div>
                            <div class="analysis-box-right">
                                <div class="analysis-box-right-left">
                                    <div class="study">
                                        <div class="top flex flex_s">
                                            <img :data-src="iconImg">
                                            <div class="rigth-title word-class">课程内容</div>
                                        </div>
                                        <div class="list">
                                            <div v-for="(items, index) in analysisList[analysisIndex].study"
                                                :key="items">
                                                <div class="yuan"></div>
                                                <div class="list-item word-class">{{ items }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex" style="margin-left: 4px;align-items: center">
                                    <img :src="analysisList[analysisIndex].imgUrl" class="analysisList-img">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 全面解析人工智能核心技能 打造人工智能实战精英 -->
                <div class="analysis-box" v-if="ismobile">
                    <div>
                        <div class="big-title analysis-title" id="shiherenqun">全面解析人工智能核心技能 <br> 打造人工智能实战精英</div>
                        <img class="title-line yd-line" :data-src="titleLineBg">
                        <div v-for="(item, index) in analysisList" class='six-total-box'>
                            <div class="six-top-box flex">
                                <div class="flex" style="align-items: center;">
                                    <img :data-src="analysisLogo" alt="" class="six-logo">
                                    <p class="six-title word-class">{{item.leftTitle}}</p>
                                </div>
                                <div class="right-logo">
                                    <img :data-src="qiziImg">
                                    <span class="right-logo-num">{{'0' + (index + 1)}}</span>
                                </div>
                            </div>
                            <div class="six-box" :style="{height: item.heightTotal }">
                                <div class="six-left-box">
                                    <img :data-src="item.imgUrlYd" :style="{height: item.height }">
                                </div>
                                <div>
                                    <!-- <div class="six-right-box-title">{{item.title}}</div> -->
                                    <div
                                        :class="{'six-right-box-space': item.spaceContent, 'six-right-box-container': true,'six-right-box-space-onlyClass':item.onlyClass}">
                                        <div v-for="(items, index) in analysisList[index].study" :key="items"
                                            :class="{'six-right-box': !item.spaceContent}">
                                            <div class="flex-start">
                                                <img :data-src="duigouImg" alt=""
                                                    class="duigou">
                                                <div class="six-list-item">{{ items }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 学Python+AI 开启职场快速通道 -->
                <div v-if="!ismobile">
                    <div class="big-title" id="shiherenqun">学Python+AI 开启职场快速通道</div>
                    <img class="title-line" :data-src="titleLineBg2">
                    <div>
                        <div class="Python-tu-box">
                            <img :data-src="leftImg" alt="">
                            <img :data-src="rightImg" alt="">
                            <p class="word-class tu-title tu-title1">Python开发工程师收入情况</p>
                            <p class="word-class tu-title tu-title2">Python开发工程师历年工资变化趋势</p>
                        </div>
                    </div>
                    <div class="bottom-explain tu-explain">
                        *数据来源于职友集等招聘网站，数据样本选取日期为2024年11月28日，仅作为信息展示，不作为效果承诺
                    </div>
                </div>
                <div v-else style="background-color: #EFFBFF;padding: 8vw 4vw">
                    <div class="big-title" id="shiherenqun">学Python+AI <br>开启职场快速通道</div>
                    <img class="title-line yd-line" :data-src="titleLineBg2">
                    <div>
                        <div class="shadow-tubiao">
                            <div class="tubiao">
                                <div class="word-class yd-tu-title">Python开发工程师收入情况</div>
                                <div class="tu-img">
                                    <img :data-src="ydechart" alt="">
                                    <div class="salary-box">
                                        <span class="salary-title word-class">平均工资</span>
                                        <span class="salary-num word-class">￥17.6K/月</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="shadow-tubiao">
                            <div class="tubiao tubiao2">
                                <div class="word-class yd-tu-title yd-tu-title2">Python开发工程师历年工资变化趋势</div>
                                <div class="tu-img">
                                    <img :data-src="ydechart2" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-explain tu-explain">
                        *数据来源于职友集等招聘网站，数据样本选取日期为2024年11月28日，仅作为信息展示，不作为效果承诺
                    </div>
                </div>
                <!-- 提前了解人工智能培训 让你不走弯路 -->
                <div class="bg-class">
                    <div class="know-box">
                        <div class="big-title" id="shiherenqun" v-if="!ismobile">提前了解人工智能培训 让你不走弯路</div>
                        <div class="big-title" id="shiherenqun" v-else>提前了解人工智能培训 <br> 让你不走弯路</div>
                        <img class="title-line" :data-src="titleLineBg2">
                        <div class="flex know-yd">
                            <div v-for="(item,index) in knowList" :key="index">
                                <div class="know-box-list">
                                    <div class="know-box-item" :style="{ background: item.bgcolor }">
                                        <img :data-src="item.imgUrl" alt="" class="know-box-item-img">
                                    </div>
                                    <div class="know-tips word-class">
                                        {{item.tips}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex question" v-if='!ismobile'>
                            <div v-for="(item, index) in knowContentList" :key="index" class="question-box">
                                <div class="question-title-box" :style="{ background: item.bgcolor }">
                                    <p class="word-class">{{ item.title }}</p>
                                </div>
                                <div class="question-content-box">
                                    <div v-for="(contentItem, contentIndex) in item.content" :key="contentIndex"
                                        class="custom-list-item">
                                        <div class="question-yuan"></div>
                                        <div class="question-item word-class">{{ contentItem }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <bottom_public_new v-if="!ismobile" :bgPColor="bgPColor"></bottom_public_new>
                <bottom_public_new_yd v-else :bgcolor="bgcolor" :aboutBg="bgPColor"></bottom_public_new_yd>
            </div>
        </div>
</template>

<script>
    import Swiper from "swiper";
    import "swiper/css/swiper.min.css";
    import "swiper/js/swiper.min.js";
    import bottom_public_new from "../../components/bottom_public_new/bottom_public_new";
    import bottom_public_new_yd from "../../components/bottom_public_new_yd/bottom_public_new_yd";
    import header_public from "../../components/header_public/header_public";
    import header_public_yd from "../../components/header_public_yd/header_public_yd";
    export default {
        metaInfo: {
            title: "【积云教育官网】短视频运营|直播带货|Python培训|数字技术培训机构",
            meta: [{
                name: "description",
                content: "积云教育,IT职业教育领导品牌.以促进稳定高薪就业为导向,开设AI短视频大师班,人工智能Python,AI数字媒体,国际数字人才专修班等热门IT课程.积云教育分校遍布全国,坚持精品课程研发,口碑好,学员及家长认可的数字媒体培训机构.",
            },
            {
                name: "keywords",
                content: "积云教育,IT培训,短视频运营,Python培训,直播带货,数字技术培训机构",
            },
            ],
        },
        components: {
            bottom_public_new,
            bottom_public_new_yd,
            header_public,
            header_public_yd
        },
        computed: {
            suitableListPc() {
                return this.innerWidth <= 768 ? this.suitableListTotal.slice(0, 3) : this.suitableListTotal;
            },
        },
        data() {
            return {
                bgcolor: 'linear-gradient(68deg, #63D8BD, #4BCBBC)',
                phoneTitle: '咨询',
                isCollapse: false,
                innerWidth: 0,
                ismobile: false,
                bgPColor: '#EFFBFF',
                headerColor: '#010C27',
                bannerBgPC: 'https://jyoa-1300845702.cos.ap-beijing.myqcloud.com/uploads/20250118/1a72b01721043343fafdbd557b9ea550.png',
                bannerBgYD: 'https://jyoa-1300845702.cos.ap-beijing.myqcloud.com/uploads/20250118/128b5ba46e7d9a16d4b624e067a43803.png',
                titleLineBg:require('../../assets/intelligenceImg/title-line.png'),
                titleLineBg2:require('../../assets/intelligenceImg/title-line2.png'),
                jianTouImg:require('../../assets/intelligenceImg/jiantou.png'),
                iconImg:require('../../assets/intelligenceImg/icon.png'),
                analysisLogo:require('../../assets/intelligenceImg/logo.png'),
                qiziImg:require('../../assets/intelligenceImg/qizi.png'),
                duigouImg:require('../../assets/intelligenceImg/duigou.png'),
                leftImg:require('../../assets/intelligenceImg/leftImg.png'),
                rightImg:require('../../assets/intelligenceImg/rightImg.png'),
                ydechart:require('../../assets/intelligenceImg/ydechart.png'),
                ydechart2:require('../../assets/intelligenceImg/ydechart2.png'),
                aiSceneList: [
                    {
                        title: '智慧城市'
                    },
                    {
                        title: '智能家居'
                    },
                    {
                        title: '车路协同'
                    },
                    {
                        title: '自动驾驶'
                    },
                    {
                        title: '数字孪生城市'
                    },
                    {
                        title: '智能机器人'
                    },
                ],
                aiSceneOutList: [
                    {
                        title: 'AI+农业'
                    },
                    {
                        title: 'AI+金融'
                    },
                    {
                        title: 'AI+制造'
                    },
                    {
                        title: 'AI+物流'
                    },
                    {
                        title: 'AI+商业'
                    },
                    {
                        title: 'AI+医疗'
                    },
                    {
                        title: 'AI+政务'
                    },
                    {
                        title: 'AI+零售'
                    },
                    {
                        title: 'AI+能源'
                    },
                    {
                        title: '......'
                    },
                ],
                fitList: [//哪些人适合学习短视频
                    {
                        imgUrl: require('../../assets/intelligenceImg/contentImgOne.png'),
                        imgUrlYd: require('../../assets/intelligenceImg/contentImgOneYd.png'),
                        title: '刚毕业想把握未来',
                        content: '想抓住未来方向的但又无从下手的迫切求职者',
                        bgcolor: 'linear-gradient(68deg, #37B7FF,#59C3EB)',
                    },
                    {
                        imgUrl: require('../../assets/intelligenceImg/contentImgTwo.png'),
                        imgUrlYd: require('../../assets/intelligenceImg/contentImgTwoYd.png'),
                        title: '有基础想寻求提升',
                        content: '正在从事人工智能、Python开发等IT岗位，想寻求技术提升',
                        bgcolor: 'linear-gradient(68deg, #63D8BD,#4BCBBC)',
                    },
                    {
                        imgUrlYd: require('../../assets/intelligenceImg/contentImgThreeYd.png'),
                        imgUrl: require('../../assets/intelligenceImg/contentImgThree.png'),
                        title: '想转行没有目标',
                        content: '受够了传统行业的迷茫和窘迫，想要改变生活的求变者',
                        bgcolor: 'linear-gradient(68deg, #FFA760,#FF9054)',
                    },
                    {
                        imgUrl: require('../../assets/intelligenceImg/contentImgFour.png'),
                        imgUrlYd: require('../../assets/intelligenceImg/contentImgFourYd.png'),
                        title: '热爱人工智能',
                        content: '喜欢互联网行业，追求新技术、新热点，想挑战高薪',
                        bgcolor: 'linear-gradient(68deg, #7F9FFF,#5D6FFF)',
                    },
                ],
                moveNineIndex: 0,
                analysisIndex: 0,
                knowIndex: 0,
                nineList: [//9大课程优势
                    {
                        imgUrlActive: require('../../assets/intelligenceImg/ketangxiaoce.png'),
                        imgUrl: require('../../assets/intelligenceImg/ketangxiaoceano.png'),
                        logoImg: require('../../assets/intelligenceImg/hexagon-fill-no.png'),
                        logoImgYd: require('../../assets/intelligenceImg/ketangxiaoceyd.png'),
                        activeLogo: require('../../assets/intelligenceImg/hexagon-fill.png'),
                        title: '学练结合实战教学',
                        height: '6.4vw',
                        width: '6.4vw',
                        content: '实战教学对标“真实工作流”，学员在学练结合中实现知识点的“即学-即用-即检验”',
                    },
                    {
                        imgUrlActive: require('../../assets/intelligenceImg/gexingactive.png'),
                        imgUrl: require('../../assets/intelligenceImg/gexing.png'),
                        logoImgYd: require('../../assets/intelligenceImg/gexingyd.png'),
                        logoImg: require('../../assets/intelligenceImg/hexagon-fill-no.png'),
                        activeLogo: require('../../assets/intelligenceImg/hexagon-fill.png'),
                        title: '个性化教学辅导',
                        content: '针对不同学员学习情况，定制1V1详细的个性化学习计划及完善的check辅导流',
                        height: '7.5vw',
                        width: '7.5vw',
                    },
                    {
                        imgUrlActive: require('../../assets/intelligenceImg/jujiao.png'),
                        imgUrl: require('../../assets/intelligenceImg/jujiaono.png'),
                        logoImgYd: require('../../assets/intelligenceImg/jujiaoyd.png'),
                        logoImg: require('../../assets/intelligenceImg/hexagon-fill-no.png'),
                        activeLogo: require('../../assets/intelligenceImg/hexagon-fill.png'),
                        title: '聚焦培养创新思维',
                        content: '培养学员创新思维，鼓励自主探究、发现和解决问题，培养终身学习理念，更好适应AI领域快速发展需求',
                        height: '8.1vw',
                        width: '8.1vw',
                    },
                    {
                        imgUrlActive: require('../../assets/intelligenceImg/hezuoactive.png'),
                        imgUrl: require('../../assets/intelligenceImg/hezuo.png'),
                        logoImgYd: require('../../assets/intelligenceImg/hezuoyd.png'),
                        logoImg: require('../../assets/intelligenceImg/hexagon-fill-no.png'),
                        activeLogo: require('../../assets/intelligenceImg/hexagon-fill.png'),
                        title: '合作探究式学习',
                        content: '强调学员之间的合作交流，通过项目实战、探究问题、解决问题等方式培养学员学习能力和创新精神',
                        height: '6.3vw',
                        width: '6.3vw',
                    },
                    {
                        imgUrlActive: require('../../assets/intelligenceImg/jiaocha.png'),
                        imgUrl: require('../../assets/intelligenceImg/jiaochano.png'),
                        logoImgYd: require('../../assets/intelligenceImg/jiaochayd.png'),
                        logoImg: require('../../assets/intelligenceImg/hexagon-fill-no.png'),
                        activeLogo: require('../../assets/intelligenceImg/hexagon-fill.png'),
                        title: '跨学科交叉培养',
                        content: '带领学员进行跨学科协作交流，实战项目打通“前端-中台-后端”全流程，拓宽学员技术视野和学科广度',
                        height: '6.7vw',
                        width: '6.7vw',
                    },
                    {
                        imgUrlActive: require('../../assets/intelligenceImg/xiangmuactive.png'),
                        imgUrl: require('../../assets/intelligenceImg/xiangmu.png'),
                        logoImgYd: require('../../assets/intelligenceImg/xiangmuyd.png'),
                        logoImg: require('../../assets/intelligenceImg/hexagon-fill-no.png'),
                        activeLogo: require('../../assets/intelligenceImg/hexagon-fill.png'),
                        title: '高端实战项目',
                        content: '倾力打造高端项目案例库，自研“WEB+AI内容推荐系统”，完整复现企业级个性化推荐逻辑，深度对标一线大厂业务',
                        width: '6.7vw',
                        height: '5.6vw',
                    },
                    {
                        imgUrlActive: require('../../assets/intelligenceImg/gexingzhuangbanactive.png'),
                        imgUrl: require('../../assets/intelligenceImg/gexingzhuangban.png'),
                        logoImg: require('../../assets/intelligenceImg/hexagon-fill-no.png'),
                        logoImgYd: require('../../assets/intelligenceImg/gexingzhuangbanyd.png'),
                        activeLogo: require('../../assets/intelligenceImg/hexagon-fill.png'),
                        title: '高标准/精技术/严保障',
                        titleYd: '高标准/精技术',
                        content: '课程研发及教学过程高标准把关，聚焦AI大模型前沿技术，项目研发-评审-验收过程循序渐进，严格保障项目实训质量，契合市场主流需求',
                        height: '6.3vw',
                        width: '6.3vw',
                    },
                    {
                        imgUrlActive: require('../../assets/intelligenceImg/mingxingactive.png'),
                        imgUrl: require('../../assets/intelligenceImg/mingxing.png'),
                        logoImgYd: require('../../assets/intelligenceImg/mingxingyd.png'),
                        logoImg: require('../../assets/intelligenceImg/hexagon-fill-no.png'),
                        activeLogo: require('../../assets/intelligenceImg/hexagon-fill.png'),
                        title: '全明星讲师阵容',
                        content: '汇聚一线大厂教研师资团队，拥有丰富教学经验和卓越教学战绩，带领学员乘风破浪高薪就业',
                        height: '6.8vw',
                        width: '6.8vw',
                    },
                    {
                        imgUrlActive: require('../../assets/intelligenceImg/robot-2-line.png'),
                        imgUrl: require('../../assets/intelligenceImg/robot-2-lineno.png'),
                        logoImgYd: require('../../assets/intelligenceImg/robot-2-lineyd.png'),
                        logoImg: require('../../assets/intelligenceImg/hexagon-fill-no.png'),
                        activeLogo: require('../../assets/intelligenceImg/hexagon-fill.png'),
                        title: '大模型•AIGC•ChatGPT',
                        titleYd: '大模型•AIGC',
                        content: '自然语言处理、计算机视觉、GANs、VAEs、图像生成、文本生成、游戏设计、虚拟现实、对话生成、智能助手...',
                        width: '7.6vw',
                        height: '6.7vw',
                    },
                ],
                analysisList: [//全面分析人智能
                    {
                        leftTitle: '数据库操作能力培养',
                        rightTitle: '课程内容',
                        study: ['熟练使用Python', '掌握开发必备Python高级语法', '掌握SQL',],
                        imgUrl: require('../../assets/intelligenceImg/hx1.png'),
                        imgUrlYd: require('../../assets/intelligenceImg/hxyd1.png'),
                        height: '21.2vw',
                        heightTotal: '27.1vw'
                    },
                    {
                        leftTitle: '框架及项目开发能力培养',
                        rightTitle: '课程内容',
                        study: ['框架的结构及搭建', '项目的架构分析设计', '项目核心功能模块的开发', '数据库的优化'],
                        imgUrl: require('../../assets/intelligenceImg/hx2.png'),
                        imgUrlYd: require('../../assets/intelligenceImg/hxyd2.png'),
                        height: '27.3vw',
                        heightTotal: '33.2vw'
                    },
                    {
                        leftTitle: '大模型技术实战能力培养',
                        rightTitle: '课程内容',
                        study: ['掌握chatgpt常见应用场景', '完成大模型项目全流程开发', '大模型项目实战入门'],
                        imgUrl: require('../../assets/intelligenceImg/hx3.png'),
                        imgUrlYd: require('../../assets/intelligenceImg/hxyd3.png'),
                        height: '21.2vw',
                        heightTotal: '27.1vw'
                    },
                    {
                        leftTitle: '企业级项目开发能力培养',
                        rightTitle: '课程内容',
                        study: ['掌握大模型微调方法', '掌握大模型微调训练框架', '掌握多场景项目实战流程', '掌握大模型的设计与训练'],
                        imgUrl: require('../../assets/intelligenceImg/hx4.png'),
                        imgUrlYd: require('../../assets/intelligenceImg/hxyd4.png'),
                        height: '27.3vw',
                        heightTotal: '33.2vw'
                    },
                ],
                knowList: [//提前了解
                    {
                        bgcolor: 'linear-gradient(68deg, #FF72D2, #FE5890)',
                        imgUrl: require('../../assets/intelligenceImg/geren.png'),
                        tips: '报名条件',
                    },
                    {
                        bgcolor: 'linear-gradient(68deg, #E7453D, #FF7F8A)',
                        imgUrl: require('../../assets/intelligenceImg/hongqiactive.png'),
                        tips: '近期开班',
                    },
                    {
                        bgcolor: 'linear-gradient(68deg, #FFB65E, #FF7C3B)',
                        imgUrl: require('../../assets/intelligenceImg/youhuiquan.png'),
                        tips: '报名优惠',
                    },
                    {
                        bgcolor: 'linear-gradient(68deg, #EFA83B, #FFC05F)',
                        imgUrl: require('../../assets/intelligenceImg/my-ledger-block.png'),
                        tips: '学习费用',
                    },
                    {
                        bgcolor: 'linear-gradient(68deg, #63D8BD, #4BCBBC)',
                        imgUrl: require('../../assets/intelligenceImg/shangke.png'),
                        tips: '上课方式',
                    },
                    {
                        bgcolor: 'linear-gradient(68deg, #00A5ED, #67D3FF)',
                        imgUrl: require('../../assets/intelligenceImg/xinzipeizhi.png'),
                        tips: '岗位薪资',
                    },
                    {
                        bgcolor: 'linear-gradient(68deg, #3F70E9, #36B2FF)',
                        imgUrl: require('../../assets/intelligenceImg/shijianriliactive.png'),
                        tips: '学习周期',
                    },
                    {
                        bgcolor: 'linear-gradient(68deg, #5B51F3, #728CFF)',
                        imgUrl: require('../../assets/intelligenceImg/chaxunren.png'),
                        tips: '求职方向',
                    },
                ],
                knowContentList: [
                    {
                        title: '人工智能培训机构问答',
                        bgcolor: 'linear-gradient(68deg, #00A5ED, #59C3EB)',
                        content: ['人工智能培训机构哪个口碑好', '人工智能培训机构哪个靠谱', '如何选择合适的人工智能培训机构', '课程会随着人工智能技术的发展更新吗', '如何选择适合自己的人工智能培训学校'],
                    },
                    {
                        title: '零基础学习人工智能',
                        bgcolor: 'linear-gradient(68deg, #63D8BD, #4BCBBC)',
                        content: ['零基础如何入门人工智能', '零基础学人工智能好学吗', '零基础小白如何学会人工智能', '有没有针对零基础学员的教学安排？', '如何零基础自学人工智能编程'],
                    },
                    {
                        title: '人工智能就业相关答疑',
                        bgcolor: 'linear-gradient(68deg, #FFA760, #FF9253)',
                        content: ['人工智能程序员的就业前景怎么样', ' 学完能达到怎样的人工智能编程水平？', '为什么说人工智能的就业面广', '参加人工智能培训好就业吗', '女生学人工智能就业前景好吗'],
                    },
                ],
                aboutContent: '积云教育是一家提供高端数字人才培训和高薪就业整体解决方案的教育集团，始终秉持“人人皆可成才”的育人理念、以“创新教育、成就未来”为使命、以“高就业率、高就业薪资、高对口就业率”为培养目标、坚持“产教融合、校企合一”。集团总部位于北京，现拥有北京、河南等多个线下大校区，累计服务全国35000多名学员',
            }
        },
        created() {
        },
        mounted() {
            this.innerWidth = window.innerWidth
            this.ismobile = this.innerWidth <= 768 ? true : false
            window.addEventListener("resize", this.handleResize);
            setTimeout(() => {
                this.initLazyLoading()
            }, 0);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            initLazyLoading() {
                this.initImgLoading()
                this.initBgLoading()
            },
            initImgLoading(){
                const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            this.loadImage(entry.target, observer);
                        }
                    });
                }, {
                    rootMargin: '50px',
                    threshold: 0.1
                });
                document.querySelectorAll('img').forEach(img => {
                    observer.observe(img);
                });
            },
            loadImage(img, observer) {
                const src = img.getAttribute('data-src');
                if (src) {
                    img.src = src;
                    // alert(1)
                    img.onload = () => {
                        observer.unobserve(img);
                    };
                }
            },
            initBgLoading(){
                const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            this.loadBg(entry.target, observer);
                        }
                    });
                }, {
                    rootMargin: '50px',
                    threshold: 0.1
                });
                document.querySelectorAll('.lazy-bg').forEach(img => {
                    observer.observe(img);
                });
            },
            loadBg(img, observer) {
                let src;
                if(this.isPC()){
                    src = img.getAttribute('data-bg');
                }else{
                    src = img.getAttribute('data-bg-m');
                }
                if (src) {
                    img.style.setProperty('background-image', `url(${window.location.origin + src})`, 'important');
                    observer.unobserve(img);
                }
            },
            addClose(value) {
                this.isCollapse = value
            },
            handleResize() {
                this.innerWidth = window.innerWidth
                this.ismobile = this.innerWidth <= 768 ? true : false
            },
            kefu() {
                window.mantis.requestChat()
            },
            moveNine(i) {
                this.moveNineIndex = i
            },
            leaveNine() {
                this.moveNineIndex = 0
            },
            moveAnalysis(i) {
                this.analysisIndex = i
            },
            leaveAnalysis() {
                this.analysisIndex = 0
            },
        }
    }
</script>

<style scoped src="../../assets/css/intelligence/intelligencenew.css"></style>